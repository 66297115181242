<template>
	<div class="main-content">
		<ktv-breadcrumb :title="$t('Contract Detail', 'Contract Detail')" page="Contract" folder="Loan" />
		<b-row>
			<b-col md="12 mb-30">
				<b-row class="mt-2">
					<b-col>
						<router-link v-b-tooltip.hover :to="{ name: 'Loan Contract List' }">
							<i class="i-Arrow-Left-2 text-10" />
							{{ $t("Back to List", "Back to List") }}
						</router-link>
					</b-col>
				</b-row>
				<b-row class="mb-4">
					<b-col />
					<b-col cols="10">
						<steps :active-index="activeTabIndex" @on-change="onStepChange" />
					</b-col>
					<b-col />
				</b-row>
				<div>
					<b-card border-variant="danger" :header="$t('Contract', 'Contract')" header-bg-variant="primary" header-text-variant="white" align="left">
						<b-alert v-if="is.loading" variant="primary" show>{{ $t("Loading", "Loading") }}...</b-alert>
						<b-tabs v-if="!is.loading" active-nav-item-class="nav nav-tabs" lazy>
							<application-detail :active-tab="activeTab" @on-tab-change="($event) => (activeTab = $event)" />
							<credit-score :active-tab="activeTab" @on-tab-change="($event) => (activeTab = $event)" />
							<loan-contract :active-tab="activeTab" @on-tab-change="($event) => (activeTab = $event)" />
							<disbursement :active-tab="activeTab" @on-tab-change="($event) => (activeTab = $event)" />
							<repayment :active-tab="activeTab" @on-tab-change="($event) => (activeTab = $event)" />
						</b-tabs>
					</b-card>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import ApplicationDetail from "./_ApplicationDetail"
	import CreditScore from "./_CreditScore"
	import Disbursement from "./_Disbursement"
	import LoanContract from "./_LoanContract"
	import Repayment from "./_Repayment"
	import Steps from "./_Steps"

	export default {
		name: "LoanContractDetail",
		metaInfo: {
			title: "Contract Detail",
		},
		components: { ApplicationDetail, CreditScore, Disbursement, LoanContract, Repayment, Steps },
		data() {
			return {
				activeTab: "ApplicationDetail",
				is: {
					loading: false,
				},
			}
		},
		computed: {
			activeTabIndex() {
				return {
					ApplicationDetail: 0,
					CreditScore: 0,
					LoanContract: 0,
					Disbursement: 1,
					Repayment: 2,
					Done: 3,
				}[this.activeTab]
			},
		},
		methods: {
			onStepChange(activeIndex) {
				this.activeTab = {
					0: "ApplicationDetail",
					1: "Disbursement",
					2: "Repayment",
					3: "Done",
				}[activeIndex]
			},
		},
	}
</script>
