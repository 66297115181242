<template>
	<div class="main-content">
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row class="d-flex text-center my-32 pt-32">
				<b-col>
					<span style="font-size: 16pt">PERJANJIAN PINJAMAN</span>
				</b-col>
			</b-row>
			<b-row class="d-flex text-center mt-32">
				<b-col>
					<span style="font-size: 16pt">Nomor: {{ id }}</span>
				</b-col>
			</b-row>
			<b-row class="d-flex text-center mb-32 pb-32">
				<b-col>
					<span style="font-size: 16pt">Tanggal: {{ agreement.contractDateActivated }}</span>
				</b-col>
			</b-row>
			<b-row class="d-flex text-center my-32 py-32">
				<b-col>
					<span style="font-size: 16pt">Jumlah Pinjaman: {{ agreement.totalAmount | Number }}</span>
				</b-col>
			</b-row>
			<b-row class="d-flex text-center mt-32 pt-32">
				<b-col>
					<span style="font-size: 16pt">Antara</span>
				</b-col>
			</b-row>
			<b-row class="d-flex text-center">
				<b-col>
					<span style="font-size: 16pt">{{ agreement.borrower }}</span>
				</b-col>
			</b-row>
			<b-row class="d-flex text-center">
				<b-col>
					<span style="font-size: 16pt">dan</span>
				</b-col>
			</b-row>
			<b-row class="d-flex text-center">
				<b-col>
					<span style="font-size: 16pt">{{ agreement.lender }}</span>
				</b-col>
			</b-row>
			<b-row class="d-flex text-center mt-32 pt-32">
				<b-col>
					<img :src="agreement.lenderLogo" style="width: auto; height: 50px" />
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row class="d-flex text-center my-32 py-32">
				<b-col>
					<span style="font-size: 11pt">RINCIAN STRUKTUR PINJAMAN</span>
				</b-col>
			</b-row>
			<b-row class="d-flex text-center">
				<b-col>
					<table class="first-table">
						<tbody>
							<tr>
								<td>Peminjam</td>
								<td>{{ agreement.borrower }}</td>
							</tr>
							<tr>
								<td>Nama Akun</td>
								<td>{{ agreement.accHolderName }}</td>
							</tr>
							<tr>
								<td>Nomor Akun</td>
								<td>{{ agreement.accNumber }}</td>
							</tr>
							<tr>
								<td>Alamat</td>
								<td>{{ agreement.borrowerDetail.address }}</td>
							</tr>
							<tr>
								<td>Email</td>
								<td>{{ agreement.email }}</td>
							</tr>
							<tr>
								<td>Nomor Telepon</td>
								<td>{{ agreement.borrowerDetail.phoneNumber }}</td>
							</tr>
							<tr>
								<td>Jenis Pinjaman</td>
								<td>{{ agreement.typeOfLoan }}</td>
							</tr>
							<tr>
								<td>Mata Uang</td>
								<td>{{ agreement.currency }}</td>
							</tr>
							<tr>
								<td>Jumlah Pokok Pinjaman</td>
								<td>{{ agreement.totalAmount | Number }}</td>
							</tr>
							<tr>
								<td>Tanggal Jatuh Tempo</td>
								<td>{{ agreement.paymentDate }}</td>
							</tr>
							<tr>
								<td>Tenor</td>
								<td>{{ agreement.tenor }} Bulan</td>
							</tr>
							<tr>
								<td>Suku Bunga</td>
								<td>{{ agreement.margin }}%</td>
							</tr>
							<tr>
								<td>Angsuran Per Bulan</td>
								<td>{{ agreement.amountPerMount }}</td>
							</tr>
							<tr>
								<td>Tanggal Pembayaran</td>
								<td>{{ agreement.paymentDate }}</td>
							</tr>
							<tr>
								<td>Denda Keterlambatan</td>
								<td>{{ agreement.fines }}</td>
							</tr>
							<tr>
								<td>Jaminan</td>
								<td>{{ agreement.guarantees }}</td>
							</tr>
							<tr>
								<td>Bentuk Jaminan</td>
								<td>{{ agreement.formOfGuarantess }}</td>
							</tr>
							<tr>
								<td>Pemberi Jaminan</td>
								<td>{{ agreement.bailGiver }}</td>
							</tr>
							<tr>
								<td>Biaya Administrasi</td>
								<td>{{ agreement.administrativeExpenses }}</td>
							</tr>
						</tbody>
					</table>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row class="d-flex mt-32 pt-32">
				<b-col>
					<span style="font-size: 11pt">Informasi Identitas Pemberi Pinjaman</span>
				</b-col>
			</b-row>
			<b-row class="d-flex mt-32">
				<b-col>
					<table>
						<tbody>
							<tr>
								<td>Nama</td>
								<td>{{ agreement.lender }}</td>
							</tr>
							<tr>
								<td>Jenis Badan Hukum</td>
								<td>{{ agreement.lenderDetail.legalStatus }}</td>
							</tr>
							<tr>
								<td>Alamat</td>
								<td>{{ agreement.lenderDetail.address }}</td>
							</tr>
							<tr>
								<td>Nama Penandatangan</td>
								<td>{{ agreement.lenderDetail.staffName }}</td>
							</tr>
							<tr>
								<td>Jabatan Penandatangan</td>
								<td>{{ agreement.lenderDetail.level }}</td>
							</tr>
							<tr>
								<td>Dokumen Identitas</td>
								<td>{{ agreement.identityDocument }}</td>
							</tr>
							<tr>
								<td>Nomor Identitas</td>
								<td>{{ agreement.identityNumber }}</td>
							</tr>
						</tbody>
					</table>
				</b-col>
			</b-row>
			<b-row class="d-flex mt-32">
				<b-col>
					<span style="font-size: 11pt">(untuk Selanjutnya disebut <b>"Pemberi Pinjaman"</b>), dan</span>
				</b-col>
			</b-row>
			<b-row class="d-flex mt-32 pt-32">
				<b-col>
					<span style="font-size: 11pt">Informasi Identitas Penerima Pinjaman</span>
				</b-col>
			</b-row>
			<b-row class="d-flex mt-32">
				<b-col>
					<table>
						<tbody>
							<tr>
								<td>Nama</td>
								<td>{{ agreement.borrower }}</td>
							</tr>
							<tr>
								<td>Tanggal Lahir</td>
								<td>{{ agreement.borrowerDetail.birthDate }}</td>
							</tr>
							<tr>
								<td>Nomor Telepon</td>
								<td>{{ agreement.borrowerDetail.phoneNumber }}</td>
							</tr>
							<tr>
								<td>Nomor KTP</td>
								<td>{{ agreement.lenderDetail.identityNumber }}</td>
							</tr>
							<tr>
								<td>Alamat Sesuai KTP</td>
								<td>{{ agreement.borrowerDetail.address }}</td>
							</tr>
						</tbody>
					</table>
				</b-col>
			</b-row>
			<b-row class="d-flex mt-32">
				<b-col>
					<span style="font-size: 11pt">(untuk Selanjutnya disebut <b>"Penerima Pinjaman"</b>), dan</span>
				</b-col>
			</b-row>
			<b-row class="d-flex">
				<b-col>
					<span style="font-size: 11pt">Pemberi Pinjaman dan Penerima Pinjaman secara bersama - sama disebut <b>"Para Pihak"</b>.</span>
				</b-col>
			</b-row>
			<b-row class="d-flex mt-32">
				<b-col>
					<span style="font-size: 11pt">
						Bahwa dengan ini Penerima Pinjaman menyatakan seluruh keterangan data diri sebagaimana yang tercantum di atas adalah benar dan dapat
						dipertanggungjawabkan secara hukum.
					</span>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row class="d-flex mt-32">
				<b-col>
					<span style="font-size: 11pt">
						Para Pihak sepakat bahwa Pemberi Pinjaman akan memberikan pinjaman kepada Penerima Pinjaman, dan Penerima Pinjaman menyetujui untuk
						meminjam dan menerima sejumlah uang dan dengan detail sebagai tertulis di bawah ini (selanjutnya disebut <b>"Pinjaman"</b>):
					</span>
				</b-col>
			</b-row>
			<b-row class="d-flex">
				<b-col>
					<ol style="font-size: 11pt">
						<li>
							Penerima Pinjaman secara sadar dan tanpa adanya paksaan berkeinginan untuk meminjam dana dari Pemberi Pinjaman melalui Platform yang
							disediakan oleh Penyedia Fasilitas.
						</li>
						<li>
							Penyedia Fasilitas adalah Koltiva yang merupakan perusahaan berbasis teknologi yang hanya memfasilitasi teknologi informasi antara
							Pemberi Pinjaman dengan Penerima Pinjaman dalam rangka melakukan perjanjian pinjam meminjam uang dalam mata uang rupiah yang
							dilakukan melalui Platform yang disediakan oleh Penyedia Fasilitas dengan menggunakan jaringan internet;
						</li>
						<li>
							Penyedia Fasilitas selalu berupaya memberikan perlindungan yang maksimal terhadap seluruh Data Pribadi yang diberikan oleh Pemberi
							Pinjaman dan/atau Penerima Pinjaman dalam rangka melakukan aktivitas pinjam meminjam.
						</li>
					</ol>
				</b-col>
			</b-row>
			<b-row class="d-flex">
				<b-col>
					<span style="font-size: 11pt">
						Oleh karena itu Para Pihak setuju untuk mengadakan Perjanjian berdasarkan syarat dan ketentuan sebagai berikut:
					</span>
				</b-col>
			</b-row>
			<b-row class="d-flex mt-32">
				<b-col>
					<ol style="font-size: 11pt">
						<li>
							<b>DEFINISI</b>
							<div>Dalam Perjanjian ini kata atau susunan kata memiliki makna khusus sebagai berikut:</div>
							<ol>
								<li>
									<b>“Agunan”</b> jaminan yang diberikan oleh Penerima Pinjaman kepada Pemberi Pinjaman dapat berupa hak tanggungan, hipotek,
									gadai, fidusia, atau hak jaminan lainnya yang dapat memberikan jaminan Penerima Pinjaman melakukan pembayaran Dana Pinjaman;
								</li>
								<li>
									<b>"Akun Penerima Pinjaman"</b> adalah akun yang telah terdaftar yang dipergunakan untuk mengakses Platform yang disediakan
									oleh Penyedia Fasilitas guna mengajukan pinjaman dan melakukan konfirmasi;
								</li>
								<li>
									<b>"Biaya Administrasi"</b> adalah biaya yang dibebankan kepada Penerima Pinjaman oleh Penyedia Fasilitas atas disetujuinya
									pengajuan Pinjaman oleh Pemberi Pinjaman melalui Platform yang disediakan oleh Penyedia Fasilitas;
								</li>
								<li>
									<b>"Calon Penerima Pinjaman"</b> adalah Warga Negara Indonesia yang telah mengajukan permohonan pinjaman namun belum
									memberikan Data Pribadi untuk diproses datanya oleh Penyedia <b>Fasilitas</b>;
								</li>
								<li>
									<b>"Dana"</b> adalah sejumlah dana dari Pemberi Pinjaman yang akan dipinjamkan kepada Penerima Pinjaman yang telah lolos
									verifikasi yang dilakukan oleh Penyedia Fasilitas;
								</li>
							</ol>
						</li>
					</ol>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ol style="font-size: 11pt">
						<li class="clear">
							<ol>
								<li index="1.6">
									<b>"Data Pribadi"</b> adalah data yang dimiliki oleh Penerima Pinjaman dan diberikan sesuai dengan persyaratan yang
									ditetapkan oleh Penyedia Fasilitas yang akan dipergunakan untuk kepentingan verifikasi yang akan dilakukan oleh Penyedia
									Fasilitas;
								</li>
								<li index="1.7">
									<b>"Denda Keterlambatan"</b> adalah Biaya yang dibebankan kepada Penerima Pinjaman apabila Penerima Pinjaman tidak dapat
									melakukan pembayaran atas pinjaman yang diterima pada waktu yang telah ditentukan;
								</li>
								<li index="1.8"><b>"Hari Kalender"</b> adalah seluruh hari termasuk hari libur nasional;</li>
								<li index="1.9">
									<b>"Hari Kerja"</b> adalah hari (selain hari Sabtu, Minggu, termasuk hari libur nasional) dimana bank dibuka untuk
									<b>usaha</b>
									pada umumnya;
								</li>
								<li index="1.10">
									<b>“IDR”</b> atau <b>“Rupiah”</b> atau <b>“Rp”</b> adalah mata uang sah Republik Indonesia sebagai alat tukar;
								</li>
								<li index="1.11">
									<b>“Indonesia”</b> adalah negara Republik Indonesia dan provinsinya dari waktu ke waktu dan termasuk, tergantung konteksnya,
									Pemerintah Republik Indonesia, Pemerintah dari provinsi dalam Republik Indonesia dan badan atau otoritas pengatur dari
									Republik Indonesia.
								</li>
								<li index="1.12">
									<b>"Jangka Waktu Pinjaman"</b> adalah jangka waktu dari pemberian Pinjaman dimulai dari Tanggal Efektif sampai dengan
									Tanggal Jatuh Tempo Pinjaman;
								</li>
								<li index="1.13">
									<b>"Jumlah Pinjaman"</b> adalah jumlah total pinjaman yang diberikan kepada Penerima Pinjaman untuk dikembalikan kepada
									Pemberi Pinjaman termasuk di dalamnya bunga, denda (jika ada) yang telah terakumulasi sebelum tanggal jatuh tempo
									sebagaimana dimaksud di dalam Perjanjian ini;
								</li>
								<li index="1.14">
									<b>"Pelunasan Lebih Awal"</b> adalah pelunasan atas Pinjaman yang diberikan oleh Penerima Pinjaman kepada Pemberi Pinjaman
									sebelum Tanggal Jatuh Tempo;
								</li>
								<li index="1.15">
									<b>"Pemberi Pinjaman"</b> adalah Lembaga keuangan bank atau bukan bank atau Lembaga lain yang mempunyai dana dan bermaksud
									untuk memberikan pinjaman kepada Penerima Pinjaman yang telah lolos verifikasi yang dilakukan oleh Penyedia Fasilitas;
								</li>
								<li index="1.16">
									<b>"Penerima Pinjaman"</b> adalah Calon Penerima Pinjaman yang telah diverifikasi oleh Penyedia Fasilitas untuk diajukan
									kepada Pemberi Pinjaman guna mendapatkan persetujuan dari Pemberi Pinjaman;
								</li>
								<li index="1.17">
									<b>“Penyedia Fasilitas"</b> adalah PT. Koltiva yang menyediakan Platform untuk mempertemukan Pemberi Pinjaman dan Penerima
									Pinjaman guna menyelenggarakan Layanan Pinjam Meminjam;
								</li>
								<li index="1.18">
									<b>"Perjanjian Pemberi Pinjaman dengan Penerima Pinjaman"</b> adalah perjanjian yang dibuat antara Pemberi Pinjaman dengan
									Penerima Pinjaman yang dilakukan melalui Platform yang disediakan oleh Penyedia Fasilitas;
								</li>
								<li index="1.19">
									<b>"Permohonan Pinjaman"</b> adalah pengajuan permohonan pinjaman yang diajukan oleh Calon Penerima Pinjaman melalui
									Platform yang disediakan oleh Penyedia Fasilitas dengan memberikan data pendukung kepada Penyedia Fasilitas dalam rangka
									untuk verifikasi guna menentukan penilaian kelayakan Pinjaman;
								</li>
							</ol>
						</li>
					</ol>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ol style="font-size: 11pt">
						<li class="clear">
							<ol>
								<li index="1.20">
									<b>"Perpanjangan Waktu Pinjaman"</b> adalah jasa yang diberikan oleh Penyedia Fasilitas kepada Penerima Pinjaman untuk dapat
									menambah jangka waktu pinjaman sesuai dengan kemampuan dari Penerima Pinjaman dan sesuai dengan persyaratan yang ditetapkan
									oleh Penyedia Fasilitas dalam Perjanjian ini;
								</li>
								<li index="1.21">
									<b>"Pinjaman"</b> adalah pinjaman yang difasilitasi oleh Pemberi Pinjaman kepada Penerima Pinjaman melalui Platform yang
									disediakan oleh Penyedia Fasilitas;
								</li>
								<li index="1.22">
									<b>"Pinjaman Pokok"</b> adalah Jumlah Pinjaman yang dibebankan kepada Penerima Pinjaman untuk <b>dikembalikan</b> kepada
									Pemberi Pinjaman dan termasuk di dalamnya Bunga dan/atau Denda (apabila ada);
								</li>
								<li index="1.23">
									<b>“Platform"</b> adalah Aplikasi yang dibuat, dimiliki dan dioperasikan oleh Penyedia Fasilitas yang saat ini terletak di
									dan dapat diakses melalui iOS dan android berikut perubahannya dari waktu ke waktu.
								</li>
								<li index="1.24">
									<b>"Rincian Struktur Pinjaman"</b> adalah sebuah informasi atas pinjaman yang telah disetujui oleh Para Pihak yang berisikan
									keterangan tentang Jumlah Pinjaman, Jangka Waktu Pinjaman, Biaya Administrasi, Bunga dan Denda (apabila ada);
								</li>
								<li index="1.25">
									<b>"Suku Bunga"</b> adalah nilai yang akan dikenakan sebagai bunga terhadap pinjaman yang tergantung pada kondisi pinjaman
									yang diajukan Penerima Pinjaman (Jangka Waktu dan Pokok Pinjaman). Penerima Pinjaman harus membayar kepada Pemberi Pinjaman
									sejumlah dana sebagaimana yang tertera dalam ketentuan Pinjaman;
								</li>
								<li index="1.26">
									<b>"Syarat dan Ketentuan"</b> adalah syarat dan ketentuan umum yang berlaku dalam Platform yang disediakan oleh Penyedia
									Fasilitas;
								</li>
								<li index="1.27">
									<b>"Tanggal Efektif"</b> adalah tanggal efektif Perjanjian ini yaitu pada saat Penerima Pinjaman menerima dana dari Pemberi
									Pinjaman;
								</li>
								<li index="1.28">
									<b>"Tanggal Jatuh Tempo"</b> adalah tanggal dimana Penerima Pinjaman harus melunasi Pinjaman kepada Pemberi Pinjaman;
								</li>
								<li index="1.29">
									<b>"Total Hutang"</b> adalah Jumlah hutang dari Penerima Pinjaman yang wajib untuk dibayar oleh Penerima Pinjaman, adapun
									unsur dari total hutang terdiri dari hutang pokok ditambah bunga ditambah Denda Keterlambatan;
								</li>
							</ol>
						</li>
						<li class="mt-32">
							<b>RUANG LINGKUP PERJANJIAN</b>
							<ol>
								<li>
									Ketentuan dari Perjanjian ini dan yang terdapat dalam platform termasuk Syarat dan Ketentuan, Pemberi Pinjaman setuju untuk
									memberikan Pinjaman kepada Penerima Pinjaman melalui Platform yang telah disediakan oleh Penyedia Fasilitas;
								</li>
								<li>
									Perjanjian ini merupakan Perjanjian Pinjaman yang mempertemukan antara Pemberi Pinjaman dan Penerima Pinjaman untuk
									melakukan kegiatan pinjam meminjam uang melalui Platform yang disediakan oleh Penyedia Fasilitas;
								</li>
								<li>
									Penerima Pinjaman wajib untuk melakukan registrasi dan/atau telah registrasi pada Platform yang disediakan oleh Penyedia
									Fasilitas dan memenuhi seluruh persyaratan yang ditentukan oleh Pemberi Pinjaman;
								</li>
							</ol>
						</li>
					</ol>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ol style="font-size: 11pt">
						<li class="clear">
							<ol>
								<li index="2.4">
									Pemberi Pinjaman atau Penerima Pinjaman dapat mengajukan permohonan untuk menghapus Data Pribadi melalui email dengan alasan
									tidak akan lagi menggunakan layanan yang disediakan oleh Penyedia Fasilitas;
								</li>
								<li index="2.5">
									Permohonan penghapusan Data Pribadi untuk Penerima Pinjaman akan dilakukan oleh Penyedia Fasilitas setelah dipastikan bahwa
									Penerima Pinjaman telah menyelesaikan seluruh kewajibannya berdasarkan Perjanjian ini.
								</li>
							</ol>
						</li>
						<li class="mt-32">
							<b>HAK DAN KEWAJIBAN PARA PIHAK</b>
							<ol>
								<li>
									<span>Hak dan Kewajiban Pemberi Pinjaman</span>
									<ol>
										<li index="a">
											Pemberi Pinjaman berhak atas hasil verifikasi dan validasi atas Data Pribadi Penerima Pinjaman yang disediakan oleh
											Penyedia Fasilitas;
										</li>
										<li index="b">
											Penerima Pinjaman dengan ini mengetahui dan sepakat bahwa Data Pribadi Penerima Pinjaman akan dapat diakses oleh
											Pemberi Pinjaman melalui Platform yang disediakan oleh Penyedia Fasilitas dengan tujuan dipergunakan oleh Pemberi
											Pinjaman untuk memberikan persetujuan atas Permohonan Pinjaman;
										</li>
										<li index="c">
											<span>
												Sehubungan dengan angka 2. di atas, Pemberi Pinjaman tidak berhak atas Data Pribadi Penerima Pinjaman kecuali
												sebatas data yang dipergunakan sebagai bahan pertimbangan dalam memberikan persetujuan atas permohonan pinjaman,
												yakni termasuk sebagai berikut:
											</span>
											<ol>
												<li index="a">Nama Lengkap;</li>
												<li index="b">Umur;</li>
												<li index="c">Tujuan Pinjaman;</li>
												<li index="d">Domisili;</li>
												<li index="e">Jenis Pinjaman;</li>
												<li index="f">Ruang Lingkup Aktivitas;</li>
												<li index="g">Total Pengalaman Kerja;</li>
												<li index="h">Gaji;</li>
												<li index="i">Sejarah Pinjaman;</li>
												<li index="j">Skoring.</li>
											</ol>
										</li>
										<li index="d">
											Pemberi Pinjaman berhak atas dana yang telah dipinjamkan kepada Penerima Pinjaman yang diberikan melalui Platform
											Penyedia Fasilitas dan bunga yang telah dinyatakan di dalam Perjanjian ini;
										</li>
										<li index="e">
											<span>
												Pemberi Pinjaman mempunyai hak untuk mengakses situs yang telah disediakan oleh Penyedia Fasilitas dalam rangka
												penggunaan dana yang mencakup unsur-unsur dibawah ini:
											</span>
											<ol>
												<li index="a">Waktu Pinjaman;</li>
												<li index="b">Jumlah Pinjaman;</li>
												<li index="c">Tujuan Pinjaman;</li>
												<li index="d">Bunga;</li>
												<li index="e">Jangka.</li>
											</ol>
										</li>
									</ol>
								</li>
							</ol>
						</li>
					</ol>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ol style="font-size: 11pt">
						<li class="clear">
							<ol>
								<li index="3.2" class="mt-16">
									<span>Hak dan Kewajiban Penerima Pinjaman</span>
									<ol>
										<li index="a">
											Penerima Pinjaman berhak menerima pinjaman yang telah disetujui oleh Pemberi Pinjaman sesuai dengan syarat dan
											ketentuan sebagaimana dimaksud di dalam perjanjian ini;
										</li>
										<li index="b">
											Penerima Pinjaman mempunyai kewajiban untuk memberikan Data Pribadi dan informasi yang benar dan lengkap kepada
											Pemberi Pinjaman melalui Penyedia Fasilitas terkait dengan setiap aspek dari informasi pribadi, kegiatan usaha dan
											/atau pekerjaan, gaji dan informasi lain yang dibutuhkan oleh Penyedia Fasilitas sehingga dapat dilakukan analisa
											dan penilaian oleh Pemberi Pinjaman apakah Penerima Pinjaman memenuhi syarat untuk mendapatkan Pinjaman;
										</li>
										<li index="c">
											Penerima Pinjaman bertanggung jawab secara hukum untuk setiap pemalsuan dan penggunaan Data Pribadi yang tidak sah
											yang diberikan kepada Penyedia Fasilitas;
										</li>
										<li index="d">
											Penerima Pinjaman mengakui sah secara hukum berhutang kepada Pemberi Pinjaman dengan jumlah penuh setelah Penerima
											Pinjaman menerima pinjaman tersebut dan setuju untuk melunasi pinjaman tersebut termasuk dengan bunga pada saat
											jatuh tempo sesuai dengan ketentuan dalam Perjanjian ini disertai dengan denda (jika ada) tanpa tuntutan balik,
											perjumpaan utang, atau pengurangan dalam bentuk apapun;
										</li>
										<li index="e">
											Penerima Pinjaman tidak dapat mengalihkan setiap hak-hak dan/atau kewajiban-kewajiban yang dimilikinya dalam
											Perjanjian ini baik sebagian maupun seluruhnya kepada pihak lain.
										</li>
									</ol>
								</li>
							</ol>
						</li>
						<li index="4" class="mt-32">
							<b>JUMLAH PINJAMAN, SUKU BUNGA DAN PERPANJANGAN JANGKA WAKTU PINJAMAN</b>
							<ol>
								<li index="4.1">
									Penerima Pinjaman setuju untuk menerima Jumlah Pinjaman sebagaimana yang dimaksud di dalam Rincian Struktur Pinjaman;
								</li>
								<li index="4.2">
									Penerima Pinjaman setuju atas suku bunga yang telah ditawarkan oleh Pemberi Pinjaman sebagaimana yang tercantum dalam
									Rincian Struktur Pinjaman;
								</li>
								<li index="4.3">
									Penerima Pinjaman harus melakukan pembayaran sesuai dengan jangka waktu yang telah dipilih secara mandiri oleh Penerima
									Pinjaman;
								</li>
								<li index="4.4">
									Untuk menghindari dikenakannya denda, Pemberi Pinjaman memberikan jasa untuk memperpanjang jangka waktu pinjaman dengan
									ketentuan Penerima Pinjaman setuju untuk membayar Biaya Perpanjangan terlebih dahulu dengan seketika dan sekaligus;
								</li>
							</ol>
						</li>
					</ol>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ol style="font-size: 11pt">
						<li class="clear">
							<ol>
								<li index="4.5">
									<span>
										Penerima Pinjaman dapat menggunakan perpanjangan jangka waktu pinjaman dengan syarat dan ketentuan sebagai berikut:
									</span>
									<ol>
										<li index="a">
											Penerima Pinjaman dapat menggunakan jasa perpanjangan jangka waktu pinjaman sebelum Tanggal Jatuh Tempo sampai
											maksimal 2 hari setelah Tanggal Jatuh Tempo. Jasa ini tidak dapat digunakan apabila telah lewat dari jangka waktu
											dimaksud;
										</li>
										<li index="b">
											Penerima Pinjaman dapat memilih perpanjangan jangka waktu pinjaman dari 15 (lima belas) sampai dengan 30 (tiga
											puluh) hari;
										</li>
										<li index="c">
											Penerima Pinjaman dapat menggunakan perpanjangan jangka waktu pinjaman dengan tanpa batasan penggunaan;
										</li>
										<li index="d">
											Apabila Penerima Pinjaman menggunakan Fasilitas Perpanjangan Jangka Waktu maka Tanggal Jatuh Tempo Perjanjian ini
											akan berubah sesuai dengan Jangka Waktu Perpanjangan yang dipilih oleh Penerima Pinjaman;
										</li>
										<li index="e">
											Pembayaran untuk perpanjangan waktu pinjaman harus dilakukan oleh Penerima Pinjaman kepada Pemberi Pinjaman secara
											seketika dan sekaligus.
										</li>
										<li index="f">
											Apabila disetujui, maka selanjutnya Penerima Pinjaman wajib melunasi pinjaman selambat lambatnya sampai tenggat
											waktu perpanjangan. Untuk menghindari keraguan, selama masa perpanjangan, bunga tidak diperhitungkan.
										</li>
									</ol>
								</li>
								<li index="4.6">
									Penerima Pinjaman diperkenankan untuk melakukan Pelunasan Lebih Awal, namun dengan ketentuan bahwa Pemberi Pinjaman tidak
									akan melakukan penghitungan ulang terhadap jumlah pinjaman, dan Penerima Pinjaman setuju untuk melakukan pembayaran sesuai
									dengan jumlah pinjaman berikut bunga;
								</li>
							</ol>
						</li>
						<li index="5" class="mt-32">
							<b>PELUNASAN</b>
							<div>
								Penerima Pinjaman wajib untuk melunasi Pinjaman secara penuh dengan melakukan pembayaran angsuran atau pokok Pinjaman kepada
								Pemberi Pinjaman pada setiap Tanggal Pembayaran sesuai dengan Jumlah Angsuran sebagaimana dijelaskan dalam Rincian Struktur
								Pinjaman di awal Perjanjian ini, termasuk dengan pembayaran bunga.
							</div>
						</li>
						<li index="6" class="mt-32">
							<b>DENDA KETERLAMBATAN</b>
							<ol>
								<li index="6.1">
									Terhadap keterlambatan pengembalian pinjaman oleh Penerima Pinjaman kepada Pemberi Pinjaman melalui Penyedia Fasilitas maka
									Penerima Pinjaman bersedia untuk dikenakan denda sebagaimana dimaksud dalam Rincian Struktur Pinjaman;
								</li>
								<li index="6.2">
									Jumlah keseluruhan biaya termasuk Denda Keterlambatan dan tidak akan melebihi 100% dari jumlah pinjaman pokok, sesuai dengan
									peraturan perundang-undangan yang berlaku; dan
								</li>
								<li index="6.3">
									Denda Keterlambatan akan dihitung apabila terjadi keterlambatan pembayaran sebagai mana yang telah disebutkan dalam Rincian
									Struktur Pinjaman. Untuk menghindari keraguan, perhitungan bunga tetap berjalan selama masa keterlambatan.
								</li>
							</ol>
						</li>
					</ol>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ol style="font-size: 11pt">
						<li index="7" class="mt-32">
							<b>AGUNAN</b>
							<ol>
								<li index="7.1">
									Guna menjamin pembayaran kembali Penerima Pinjaman wajib menyerahkan Agunan sebagai jaminan, serta menyerahkan bukti-bukti
									kepemilikan Agunan yang asli dan sah untuk diikat sesuai dengan ketentuan peraturan perundang-undangan yang berlaku.
								</li>
								<li index="7.2">
									Penerima Pinjaman wajib memberikan bantuan sepenuhnya guna memungkinkan Pemberi Pinjaman dapat melaksanakan pengikatan
									Agunan sebagai jaminan menurut cara dan pada saat yang dianggap baik oleh Pemberi Pinjaman. Bukti Kepemilikan Agunan yang
									dijadikan jaminan dikuasai oleh Pemberi Pinjaman sampai seluruh jumlah Pinjaman dilunasi.
								</li>
								<li index="7.3">Seluruh biaya dalam pengikatan Barang Jaminan menjadi tanggungan Penerima Pinjaman.</li>
							</ol>
						</li>
						<li index="8" class="mt-32">
							<b>PENAGIHAN</b>
							<div>
								Para Pihak sepakat bahwa, apabila Penerima Pinjaman gagal melakukan suatu pembayaran dalam waktu yang ditentukan, maka Penerima
								Pinjaman dengan ini memberikan otorisasi kepada Pemberi Pinjaman untuk melakukan segala tindakan yang diperlukan untuk mengambil
								pelunasan atas Jumlah Terutang dengan melakukan:
							</div>
							<ol>
								<li index="a">
									Menyatakan bahwa Pinjaman yang timbul (termasuk namun tidak terbatas pada Jumlah Pokok, suku bunga, biaya jatuh tempo (jika
									ada)) dengan segera jatuh tempo dan dibayarkan, dan Peminjam harus dengan segera membayar jumlah yang sama kepada Pemberi
									Pinjaman;
								</li>
								<li index="b">Meminta Peminjam untuk melaksanakan pembayaran Peminjam dan kewajiban finansialnya sesuai Perjanjian ini;</li>
								<li index="c">
									Menghubungi Penerima Pinjaman, termasuk melalui media sosial online Penerima Pinjaman dan berkoordinasi dengan kontak
									darurat dari Peminjam sebagaimana yang rinciannya disediakan oleh Peminjam;
								</li>
								<li index="d">
									Mengirimkan dan menyediakan pemberitahuan, pengingat, dan/atau menunjuk pihak ketiga manapun untuk mengirim dan menyediakan
									surat peringatan terhadap Peminjam;
								</li>
								<li index="e">
									Melaporkan Peminjam ke institusi pemerintah terkait, termasuk namun tidak terbatas pada membuat laporan polisi terhadap
									Peminjam; dan
								</li>
								<li index="f">
									Mengalihkan dan memindahkan setiap dan seluruh hal Pemberi Pinjaman terhadap Peminjam kepada pihak ketiga manapun sehubungan
									dengan ketentuan Perjanjian ini, dan untuk menghindari keraguan, pengalihan dan/atau pemindahan tersebut tidak mempengaruhi
									setiap kewajiban finansial, jaminan dan/janji Peminjam sesuai Perjanjian ini.
								</li>
							</ol>
						</li>
					</ol>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ol style="font-size: 11pt">
						<li index="9" class="mt-32">
							<b>PENYELESAIAN SENGKETA</b>
							<ol>
								<li index="9.1">
									Para Pihak sepakat bahwa Perjanjian Pinjaman ini diatur, ditafsirkan dan dilaksanakan berdasarkan hukum dan peraturan
									perundang-undangan yang berlaku di Indonesia.
								</li>
								<li index="9.2">
									Apabila terjadi sengketa di antara Para Pihak yang disebabkan dari pelaksanaan atau penafsiran Perjanjian ini (“
									<b>Sengketa</b> ”), maka Sengketa tersebut wajib diselesaikan secara damai dengan cara musyawarah untuk mufakat dalam waktu
									30 (tiga puluh) hari sejak tanggal pemberitahuan tertulis dari salah satu Pihak tentang timbulnya Sengketa telah diterima
									oleh Pihak lainnya.
								</li>
								<li index="9.3">
									Apabila dalam 30 (tiga puluh) hari penyelesaian dengan musyawarah antara Pemberi Pinjaman dan Penerima Pinjaman untuk
									perselisihan yang berhubungan dengan Perjanjian Pinjaman ini tidak bisa dicapai, kedua pihak sepakat untuk memilih tempat
									kedudukan hukum yang tetap dan umum di Pengadilan Negeri (*).
								</li>
							</ol>
						</li>
						<li index="10" class="mt-32">
							<b>PEMBERITAHUAN</b>
							<ol>
								<li index="10.1">
									<span>Alamat</span>
									<div>
										Semua surat menyurat atau pemberitahuan yang harus dikirim oleh masing-masing pihak kepada pihak lain dalam Perjanjian
										Pinjaman ini mengenai atau sehubungan dengan pelaksanaan Perjanjian Pinjaman ini dilakukan dengan secara langsung, surat
										tercatat, faksimile, atau melalui perusahaan ekspedisi (kurir) ke alamat-alamat yang tersebut dibawah ini:
									</div>
									<div class="mt-16">
										<div>Pemberi Pinjaman</div>
										<table>
											<tbody>
												<tr>
													<td>Nama</td>
													<td>: {{ agreement.lender }}</td>
												</tr>
												<tr>
													<td>Alamat</td>
													<td>: {{ agreement.lenderDetail.address }}</td>
												</tr>
												<tr>
													<td>Telp/Fax</td>
													<td>: {{ agreement.phoneNumber }}</td>
												</tr>
												<tr>
													<td>Email</td>
													<td>: {{ agreement.email }}</td>
												</tr>
												<tr>
													<td>Untuk Perhatian</td>
													<td>: {{ agreement.lenderDetail.staffName }}</td>
												</tr>
											</tbody>
										</table>
										<div class="mt-16">
											Data atau informasi Penerima Pinjaman seperti alamat, nomor telp, dan email sebagaimana dalam Rincian Struktur
											Pinjaman di atas.
										</div>
										<div class="mt-16">
											Setiap ada perubahan Data atau Informasi salah satu Pihak dalam Perjanjian ini berubah, maka Pihak yang mengalami
											perubahan tersebut, wajib untuk memberitahukan kepada Pihak lainnya dan Penyedia Fasilitas paling lambat 3 hari
											sebelum perubahan itu dilakukan.
										</div>
									</div>
								</li>
							</ol>
						</li>
					</ol>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ol style="font-size: 11pt">
						<li class="clear">
							<ol>
								<li index="10.2" class="mt-16">
									<span>Pemberitahuan Elektronik</span>
									<ol>
										<li index="a">
											Penerima Pinjaman dengan ini setuju bahwa setiap korespondensi sehubungan dengan Pinjaman dapat, atas diskresi penuh
											Pemberi Pinjaman, dilakukan secara elektronik, baik melalui Platform ataupun di luar Platform. Penerima Pinjaman
											berjanji untuk menerima dan tidak menggugat keabsahan atau keberlakuan setiap korespondensi yang dibuat dalam bentuk
											elektronik, termasuk namun tidak terbatas pada pemberitahuan.
										</li>
										<li index="b">
											Penerima Pinjaman memahami dan menyetujui bahwa setiap korespondensi yang dilakukan di luar Platform akan dilakukan
											melalui keterangan kontak yang tertera dalam Platform, dan Peminjam mengakui bahwa Penyedia Fasilitas tidak
											bertanggung jawab, dan Penerima Pinjaman berjanji untuk tidak akan meminta Penyedia Fasilitas untuk bertanggung
											jawab atau memberikan ganti rugi, atas korespondensi yang dibuat selain dari keterangan kontak sebagaimana
											disebutkan di atas.
										</li>
										<li index="c">
											Penandatanganan setiap surat kuasa dari Peminjam kepada Pemberi Pinjaman, perjanjian antara Peminjam dengan Pemberi
											Pinjaman akan dilakukan secara elektronik melalui tandatangan elektronik. Peminjam berjanji untuk tidak menantang
											keabsahan atau keberlakuan setiap perjanjian yang ditandatangani sehubungan dengan Pinjaman tersebut ditandatangani
											secara elektronik.
										</li>
									</ol>
								</li>
								<li index="10.3" class="mt-16">
									<span>Bahasa Indonesia</span>
									<div>
										Setiap pemberitahuan yang disampaikan berdasarkan atau berkaitan dengan Perjanjian ini harus dalam Bahasa Indonesia.
									</div>
								</li>
							</ol>
						</li>
						<li index="11" class="mt-32">
							<b>BERAKHIRNYA PERJANJIAN</b>
							<ol>
								<li index="11.1">
									Perjanjian ini berlaku efektif sejak ditandatangani oleh Para Pihak, dan berakhir setelah berakhirnya Jangka Waktu Pinjaman
									dan Penerima Pinjaman telah memenuhi seluruh kewajibannya pembayarannya berdasarkan Perjanjian Pinjaman ini atau diakhiri
									berdasarkan kesepakatan Para Pihak.
								</li>
								<li index="11.2">
									Pemberi Pinjaman melalui Penyedia Fasilitas berhak untuk mengakhiri Perjanjian ini secara sepihak dengan Penerima Pinjaman
									atas kuasa yang diberikan oleh Pemberi Pinjaman apabila Penerima Pinjaman telah dengan sengaja melanggar ketentuan dalam
									klausul mengenai Hak dan Kewajiban Penerima Pinjaman walaupun sudah diberikan peringatan baik secara lisan dan atau
									tertulis;
								</li>
								<li index="11.3">
									Bahwa pengakhiran sebagaimana dimaksud diatas tidak menghapuskan kewajiban yang timbul bagi Penerima Pinjaman sebagaimana
									yang dimaksudkan di dalam Perjanjian ini;
								</li>
							</ol>
						</li>
					</ol>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ol style="font-size: 11pt">
						<li class="clear">
							<ol>
								<li index="11.4">
									Selain itu Perjanjian ini akan berakhir pada Penerima Pinjaman melakukan Pelunasan Lebih Awal. Tanggal dilakukannya
									Pelunasan Lebih Awal tersebut akan menjadi tanggal berakhirnya Perjanjian ini. Untuk menghindari keraguan, pembayaran yang
									dilakukan oleh Penerima Pinjaman harus mencakup Jumlah Pokok, suku bunga, biaya jatuh tempo (jika ada); dan
								</li>
								<li index="11.5">
									Para Pihak sepakat mengesampingkan ketentuan Pasal 1266 dan Pasal 1267 Kitab Undang-Undang Hukum Perdata, sehingga tidak
									diperlukan keputusan atau penetapan pengadilan untuk pengakhiran tersebut.
								</li>
							</ol>
						</li>
						<li index="12" class="mt-32">
							<b>KERAHASIAAN</b>
							<ol>
								<li index="12.1">
									Para pihak wajib menjaga kerahasiaan setiap data, informasi, dokumen apapun yang dimiliki atau terkait dengan masing-masing
									pihak dan pelaksanaan Perjanjian Pinjaman ini (“Informasi Rahasia”) dan masing-masing pihak tanpa persetujuan pihak lainnya
									dilarang untuk menginformasikannya kepada pihak lain kecuali untuk keperluan pelaksaan kewajiban-kewajiban masing-masing
									pihak sesuai dengan ketentuan Perjanjian ini atau yang diwajibkan oleh undang-undang.
								</li>
								<li index="12.2">
									Informasi Rahasia yang wajib dijaga kerahasiaannya tersebut tidak termasuk setiap bagian dari keterangan yang dibuka
									tersebut atau data yang: (a) telah diketahui atau dapat diakses secara terbuka oleh masyarakat luas pada saat diterima; (b)
									merupakan dan dalam waktu selanjutnya diketahui oleh umum atau publik tanpa adanya pelanggaran ketentuan kerahasiaan oleh
									pihak yang menerima informasi; (c) dimiliki secara sah oleh pihak yang menerima informasi; (d) diberikan kepada pihak yang
									menerima informasi dari pihak ketiga tanpa melanggar ketentuan kerahasiaan apapun; (e) dengan cara lain telah atau diperoleh
									secara independen atau dikembangkan oleh pihak yang menerima informasi tanpa melanggar ketentuan-ketentuan dari Perjanjian
									Pinjaman ini.
								</li>
								<li index="12.3">
									Apabila pemerintah atau pengadilan yang karena kewenangannya memerintahkan kepada salah satu pihak untuk menyampaikan
									Informasi Rahasia tersebut, maka salah satu pihak tersebut wajib dengan segera memberitahukan sebelumnya kepada pihak
									lainnya yang memiliki informasi tersebut.
								</li>
							</ol>
						</li>
						<li index="13" class="mt-32">
							<b>PERNYATAAN DAN JAMINAN</b>
							<ol>
								<li index="13.1">
									<b>Para Pihak Menyatakan dan Menjamin</b>
									<ol>
										<li index="a">
											Perjanjian ini merupakan bersifat keperdataan antara Pemberi Pinjaman dengan Penerima Pinjaman, dan segala hak dan
											kewajiban yang timbul dari perjanjian tersebut karenanya merupakan hak-hak dan kewajiban-kewajiban para pihak yang
											berkontrak.
										</li>
									</ol>
								</li>
							</ol>
						</li>
					</ol>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ol style="font-size: 11pt">
						<li class="clear">
							<ol>
								<li class="clear">
									<ol>
										<li index="b">
											Dalam hal terjadi sengketa atau perselisihan yang terjadi diantara Para Pihak, maka Para Pihak sepakat untuk tidak
											melibatkan atau mengikutsertakan Penyedia Fasilitas terhadap sengketa atau perselisihan tersebut.
										</li>
										<li index="c">
											Para Pihak yang menandatangani Perjanjian ini adalah orang yang sah dan cakap untuk mewakili atau berhak untuk
											melaksanakan Perjanjian ini atas nama Pihak.
										</li>
									</ol>
								</li>
								<li index="13.2" class="mt-16">
									<b>Pemberi Pinjaman dan Menjamin</b>
									<ol>
										<li index="a">
											Akan menanggung seluruhnya risiko kredit atau risiko gagal bayar atas bunga, pinjaman pokok dan seluruh kerugian
											lain manapun yang mungkin timbul sehubungan dengan Perjanjian ini. Penyedia Fasilitas maupun lembaga pemerintah
											terkait manapun tidak bertanggung jawab atas risiko gagal bayar dan kerugian tersebut.
										</li>
										<li index="b">
											Telah memiliki perijinan-perijinan yang dibutuhkan untuk menjalankan kegiatan usahanya yang relevan sesuai dengan
											perundangan dan ketentuan yang berlaku.
										</li>
									</ol>
								</li>
								<li index="13.3" class="mt-16">
									<b>Penerima Pinjaman dan Menjamin</b>
									<ol>
										<li index="a">
											Dokumen dan informasi yang disampaikan ke adalah sah, benar, dan sesuai dengan kenyataan serta tidak ada kesalahan
											dan/atau ketidaktepatan informasi dan/atau informasi yang menyesatkan atau tidak diungkapkan kepada Pemberi
											Pinjaman.
										</li>
										<li index="b">
											Peminjam tidak pernah, sedang, dan/atau diperkirakan akan terlibat dalam perkara hukum, arbitrase atau persidangan
											administratif di hadapan pengadilan atau pengadilan yang tertunda atau ancaman terhadap Peminjam apapun, termasuk
											namun tidak terbatas pada perkara pidana dan perdata, yang dapat mempengaruhi keuangan Peminjam secara buruk serta
											kemampuan untuk mematuhi syarat dan ketentuan dalam Perjanjian ini, termasuk namun tidak terbatas pada kewajiban
											pembayaran kembali dan pembayaran Pinjaman;
										</li>
									</ol>
								</li>
							</ol>
						</li>
						<li index="14" class="mt-32">
							<b>KETENTUAN UMUM</b>
							<ol>
								<li index="14.1">
									<b>Perubahan</b>. Kecuali ditetapkan lain dalam Perjanjian ini, tidak ada perubahan yang dapat dilakukan terhadap ketentuan
									dalam Perjanjian ini tanpa ada persetujuan tertulis Para Pihak.
								</li>
							</ol>
						</li>
					</ol>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ol style="font-size: 11pt">
						<li class="clear">
							<ol>
								<li index="14.2">
									<b>Pengesampingan</b>. Hak-hak Para Pihak Perjanjian ini (baik yang timbul berdasarkan Perjanjian ini atau berdasarkan
									undang-undang) tidak dapat dikesampingkan atau dikurangi kecuali disepakati sebelumnya secara tertulis oleh Para Pihak.
									Keterlambatan atau kegagalan Pihak Perjanjian ini untuk melaksanakan atau menjalankan haknya yang terkait sesuai Perjanjian
									ini atau undang-undang tidak akan dianggap sebagai pengesampingan hak-hak tersebut, tidak pula pengesampingan oleh Pihak
									Perjanjian ini terhadap hak-hak tertentu yang diberikan berdasarkan Perjanjian ini atau undang-undang akan dianggap sebagai
									pengesampingan hak-hak lainnya yang diberikan berdasarkan Perjanjian ini atau undang-undang.
								</li>
								<li index="14.3">
									<b>Keterpisahan</b>. Apabila ketentuan manapun dari Perjanjian ini, atau bagian darinya, ditetapkan tidak berlaku atau tidak
									sah oleh pengadilan yang berwenang manapun, keabsahan dan/atau keberlakuan ketentuan lainnya atau bagiannya tidak akan
									terpengaruh olehnya.
								</li>
							</ol>
						</li>
					</ol>
				</b-col>
			</b-row>
		</div>
		<div class="page-wrapper html2pdf__page-break">
			<b-row class="d-flex text-right header">
				<b-col>
					<b style="font-size: 11pt">Dokumen Pribadi dan Rahasia</b>
				</b-col>
			</b-row>
			<b-row class="d-flex text-center my-32 py-32">
				<b-col>
					<span style="font-size: 11pt">KOLOM TANDA TANGAN</span>
				</b-col>
			</b-row>
			<b-row class="d-flex">
				<b-col>
					<span>
						Demikianlah Perjanjian ini disepakati para pihak dibuat dan ditandatangani pada tanggal, bulan, dan tahun sebagaimana dinyatakan pada
						awal Perjanjian ini.
					</span>
				</b-col>
			</b-row>
			<b-row class="d-flex mt-32" style="padding: 0 2rem">
				<b-col>
					<table class="sign">
						<tbody>
							<tr>
								<td>Pemberi Pinjaman</td>
								<td></td>
								<td>Penerima Pinjaman</td>
							</tr>
							<tr>
								<td>
									<b>{{ agreement.lender }}</b>
								</td>
								<td></td>
								<td>
									<b>{{ agreement.borrower }}</b>
								</td>
							</tr>
							<tr class="signature">
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td style="border-top: 1px solid black; width: 200px"></td>
								<td></td>
								<td style="border-top: 1px solid black; width: 200px"></td>
							</tr>
						</tbody>
					</table>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
	export default {
		name: "LoanContractDetailAgreementConventional",
		props: {
			agreement: {
				default: () => {},
				required: true,
				type: Object,
			},
			id: {
				default: null,
				required: false,
				type: [String, Number],
			},
		},
	}
</script>

<style lang="sass" scoped>
	ol
		counter-reset: item

	li
		display: block

		&:before
			content: counters(item, ".") ". "
			counter-increment: item

		&[index]:not([index=""])
			&:before
				content: attr(index) ". "

		&.clear
			&:before
				content: ""

	table
		width: 100%

		&.first-table
			tr
				border: 1px #ccc solid

				& > td:first-of-type
					width: 30%

				td
					padding: 0.35rem
					width: 70%

		&.sign
			tr
				width: 100%

				& > td
					width: 40%

					&:first-of-type, &:last-of-type
						width: 30%

				&.signature
					height: 140px

		tr
			& > td:first-of-type
				min-width: 150px
				width: 20%

			& > td:last-of-type
				min-width: 150px
				width: 80%

			td
				font-size: 11pt
				padding: 0.2rem
				text-align: left
				vertical-align: middle

	.header
		margin-right: 0.3cm
		margin-top: 0.3cm
		position: absolute
		right: 0
		top: 0

	.list-group-item
		border-left: none
		border-right: none
		border-bottom: 1px solid rgba(0, 0, 0, 0.125)

	.page-wrapper
		display: flex
		flex-direction: column
		height: 100vh
		padding: 2.54cm 1.91cm
		position: relative
		width: 100%
</style>
