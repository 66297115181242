<template>
	<div class="main-content">
		<ktv-modal v-model="is.showingModal" size="md" :title="$t('Repayment Add', 'Repayment Add')" no-close-on-backdrop no-close-on-esc @close="close()">
			<template #content>
				<vcl-facebook v-show="loadings" />
				<b-form v-show="!loadings">
					<b-form-group class="col-md-12 mb-2" :label="$t('Amount', 'Amount')">
						<b-form-input
							v-model="addRepayment.amountRepayment"
							v-validate="'required'"
							:state="validateState('amountRepayment')"
							data-vv-name="amountRepayment"
							type="text"
							style="background: white"
							@keydown="amountRepayment"
							@focus="onFocusRepayment"
							@blur="amountRepaymentBlur"
						/>
					</b-form-group>
					<b-form-group class="col-md-12 mb-2" :label="$t('Payment Receipt', 'Payment Receipt')">
						<b-form-file
							ref="file"
							v-model="addRepayment.fileReceipt"
							v-validate="'required'"
							:state="validateState('fileReceipt')"
							data-vv-name="fileReceipt"
							accept=".jpg,.png,.jpeg"
							style="background: white"
							@change="uploadFile"
						/>
					</b-form-group>
					<b-form-group class="col-md-12 mb-2" :label="$t('Date', 'Date')">
						<b-form-input
							v-model="addRepayment.datePayment"
							v-validate="'required'"
							:state="validateState('datePayment')"
							data-vv-name="datePayment"
							type="date"
							style="background: white"
						/>
					</b-form-group>
					<b-form-group class="col-md-12 mb-2" :label="$t('Payment Method', 'Payment Method')">
						<v-select
							v-model="addRepayment.paymentMethod"
							v-validate="'required'"
							:state="validateState('paymentMethod')"
							data-vv-name="paymentMethod"
							:options="options.paymentmethod"
						/>
					</b-form-group>
					<ktv-button v-show="!loadings" text="Submit" color="primary" size="sm" class="ml-2 float-right" @click="Save()" />
				</b-form>
			</template>
		</ktv-modal>
	</div>
</template>

<script>
	import { KtvButton, KtvModal } from "@/components"
	import { mapActions, mapGetters } from "vuex"
	export default {
		name: "LoanContractDetailRepaymentAdd",
		metaInfo: {
			title: "View Contact Detail - Repayment - Add",
		},
		components: { KtvButton, KtvModal },
		props: {
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close"],
		data() {
			return {
				loadings: false,
				addRepayment: {
					amount: "",
					datePayment: "",
					fileReceipt: null,
					paymentMethod: "",
				},
				options: {
					paymentmethod: [],
				},
				is: {
					showingModal: false,
				},
			}
		},
		computed: {
			...mapGetters({
				getPaymentLoanMethodLists: "MASTER/getPaymentLoanMethodLists",
				loggedInUser: "AUTHDATA/loggedInUser",
			}),
		},
		watch: {
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}
				},
			},
		},
		async mounted() {
			await this.PaymentMethod()
		},
		methods: {
			...mapActions({
				ActionLoanPaymentMethod: "MASTER/ActionLoanPaymentMethod",
				ActionUploadS3: "CONTRACT/ActionUploadS3",
				ActionRepaymentSave: "CONTRACT/ActionRepaymentSave",
			}),
			close() {
				this.is = {
					...this.is,
					loading: false,
				}

				this.$emit("close")
			},
			amountRepayment() {
				if (this.addRepayment.amountRepayment !== undefined) {
					return this.formatPrice(this.addRepayment.amountRepayment)
				}

				return ""
			},
			amountRepaymentBlur() {
				if (isNaN(this.addRepayment.amountRepayment)) {
					this.addRepayment.amountRepayment = 0
				} else {
					this.addRepayment.amountRepayment = this.amountRepayment()
				}
			},
			formatPrice(value) {
				let val = (value / 1).toFixed().replace(".", ",")
				return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
			},
			onFocusRepayment() {
				if (this.addRepayment.amountRepayment !== undefined && this.addRepayment.amountRepayment !== "" && this.addRepayment.amountRepayment !== 0) {
					this.addRepayment.amountRepayment = this.addRepayment.amountRepayment.split(".").join("")
				}
			},
			async PaymentMethod() {
				await this.ActionLoanPaymentMethod()
				let pMethod = []
				this.getPaymentLoanMethodLists.forEach((items) => {
					const obj = {
						id: items.entityListOfValueId,
						label: items.value,
					}
					pMethod.push(obj)
				})
				this.options.paymentmethod = pMethod
			},
			uploadFile() {
				this.addRepayment.fileReceipt = this.$refs.file.files[0]
			},
			Save() {
				this.$validator.validateAll().then((result) => {
					if (!result) {
						return
					}

					// upload data ke S3
					let amt = this.addRepayment.amountRepayment

					const formData = new FormData()
					formData.append("file_data", this.addRepayment.fileReceipt)
					formData.append("bucket_name", "koltiva-loan")
					formData.append("s3_dest_path", "loan/contract/")

					this.loadings = true
					this.ActionUploadS3(formData)
						.then((response) => {
							const payload = {
								loanContractId: this.$route.params.id,
								amount: amt.replace(/\./g, ""),
								paymentMethod: this.addRepayment.paymentMethod.id,
								installmentDatePaid: this.addRepayment.datePayment,
								createdBy: this.loggedInUser.UserId,
								documents: [
									{
										documentUrl: response.s3_url,
										documentInfo: response.file_name,
									},
								],
							}
							this.ActionRepaymentSave(payload)
								.then((res) => {
									this.recalculated()
									if (res === "Success") this.loadings = false
									this.close()
								})
								.catch(() => {
									this.loadings = false
								})
						})
						.catch(() => {
							this.loadings = false
						})
				})
			},
			recalculated() {
				this.$parent.$parent.recalculated(this.addRepayment.amountRepayment)
			},
		},
	}
</script>
