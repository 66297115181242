<template>
	<div class="main-content">
		<ktv-modal v-model="is.showingModal" size="lg" title="Disbursement Detail" no-close-on-backdrop no-close-on-esc @close="close()">
			<template #content>
				<input id="virtual-account" type="text" :value="virtualAccount" style="position: absolute; z-index: -9999; left: -9999px" />
				<input
					v-if="serviceCharge && serviceCharge.TotalPaidWithServiceCharge"
					id="amount"
					type="text"
					:value="serviceCharge.TotalPaidWithServiceCharge"
					style="position: absolute; z-index: -9999; left: -9999px"
				/>
				<b-row>
					<b-col>
						<b-list-group>
							<b-list-group-item>
								<b-row align-v="center">
									<b-col class="text-18">
										<b>Payment via {{ paymentMethod }}</b>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item>
								<div>{{ $t("Transaction ID", "Transaction ID") }}</div>
								<div>
									<b>{{ transactionId }}</b>
								</div>
							</b-list-group-item>
							<b-list-group-item>
								<b-row align-v="center">
									<b-col>
										<div>{{ $t("Virtual Account Number", "Virtual Account Number") }}</div>
										<div>
											<b id="virtual-account">{{ virtualAccount ? virtualAccount : " -" }}</b>
										</div>
									</b-col>
									<b-col class="text-20 text-primary text-right">
										<ktv-button
											v-if="!isExpired"
											:disabled="is.loading"
											:text="$t('Copy', 'Copy')"
											color="primary"
											size="sm"
											@click="copy('#virtual-account')"
										/>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item>
								<div>{{ $t("Destination", "Destination") }}</div>
								<div>
									<b>{{ accountName }}</b>
								</div>
							</b-list-group-item>
							<b-list-group-item>
								<b-row align-v="center">
									<b-col>
										<div>{{ $t("Total Payment", "Total Payment") }}</div>
										<div>
											<b v-if="serviceCharge && serviceCharge.TotalPaidWithServiceCharge">
												{{ serviceCharge.TotalPaidWithServiceCharge | Rupiah }}
											</b>
											<b v-else>-</b>
										</div>
									</b-col>
									<b-col class="text-20 text-primary text-right">
										<ktv-button :disabled="is.loading" :text="$t('Copy', 'Copy')" color="primary" size="sm" @click="copy('#amount')" />
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item>
								<b-row align-v="center">
									<b-col>
										<div>{{ $t("Complete Payment in", "Complete Payment in") }}</div>
										<div v-if="isExpired">
											<b class="text-danger">Expired</b>
										</div>
									</b-col>
								</b-row>
								<b-row align-v="center">
									<b-col>
										<vue-countdown v-if="!isExpired && expiredDate" :time="expiredDateInMiliseconds">
											<template slot-scope="props">
												<b>
													<span v-if="props.days > 0">{{ props.days }} {{ $t("days", "days") }}</span>
													<span v-if="props.days > 0 && props.hours > 0">, </span>
													<span v-if="props.hours > 0">{{ props.hours }} {{ $t("hours", "hours") }}</span>
													<span v-if="props.hours > 0 && props.minutes > 0">, </span>
													<span v-if="props.minutes > 0">{{ props.minutes }} {{ $t("minutes", "minutes") }}</span>
													<span v-if="props.minutes > 0 && props.seconds > 0">, </span>
													<span v-if="props.seconds > 0">{{ props.seconds }} {{ $t("seconds", "seconds") }}</span>
													<span>.</span>
												</b>
											</template>
										</vue-countdown>
									</b-col>
								</b-row>
								<b-row align-v="center">
									<b-col>
										{{ getDate(expiredDate) }}
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
						<b-list-group class="mt-3">
							<b-list-group-item>
								<b-row align-v="center">
									<b-col>
										<div>
											<b>{{ $t("Transaction Detail", "Transaction Detail") }}</b>
										</div>
										<div class="mt-3 d-flex flex-grow-1" style="align-items: center; justify-items: between">
											<span class="d-flex flex-grow-1">{{ $t("Transaction Amount", "Transaction Amount") }}</span>
											<span class="d-flex">
												<b>{{ amount | Rupiah }}</b>
											</span>
										</div>
										<div class="mt-3 d-flex flex-grow-1" style="align-items: center; justify-items: between">
											<span class="d-flex flex-grow-1">{{ `${$t("Service Charge", "Service Charge")}` }}</span>
											<span class="d-flex">
												<b v-if="serviceCharge && serviceCharge.ServiceCharge"> +{{ serviceCharge.ServiceCharge | Rupiah }} </b>
												<b v-else>-</b>
											</span>
										</div>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="bg-gray-100">
								<b-row align-v="center">
									<b-col>
										<div class="d-flex flex-grow-1" style="align-items: center; justify-items: between">
											<span class="d-flex flex-grow-1">
												<b>{{ $t("Total Payment", "Total Payment") }}</b>
											</span>
											<span class="d-flex">
												<b v-if="serviceCharge && serviceCharge.TotalPaidWithServiceCharge">
													{{ serviceCharge.TotalPaidWithServiceCharge | Rupiah }}
												</b>
												<b v-else>-</b>
											</span>
										</div>
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
					</b-col>
					<b-col>
						<b-list-group>
							<b-list-group-item class="text-18">
								<b>{{ $t("Payment Instruction", "Payment Instruction") }}</b>
							</b-list-group-item>
							<b-list-group-item v-for="(paymentInstruction, index) in paymentInstructions" :key="index" class="text-18">
								<b-row style="cursor: pointer" @click="visible = index">
									<b-col cols="8" class="text-18">
										<b>{{ paymentInstruction.Name }}</b>
									</b-col>
									<b-col cols="4" align="right">
										<i v-bind="accordionAttributes[index]" />
									</b-col>
								</b-row>
								<b-collapse :id="`accordion-${index}`" :visible="visible === index" accordion="my-accordion" role="tabpanel" class="text-12">
									<!-- eslint-disable vue/no-v-html -->
									<div v-html="paymentInstruction.Content" />
									<!-- eslint-enable vue/no-v-html -->
								</b-collapse>
							</b-list-group-item>
						</b-list-group>
					</b-col>
				</b-row>
			</template>
			<template #footer>
				<ktv-button :disabled="is.loading" text="Close" color="primary" size="sm" class="ml-2 float-right" @click="close()" />
			</template>
		</ktv-modal>
	</div>
</template>

<script>
	import { findWhere, map } from "underscore"
	import { KtvButton, KtvModal } from "@/components"
	import { mapActions, mapGetters } from "vuex"
	import moment from "moment"
	import VueCountdown from "@chenfengyuan/vue-countdown"

	export default {
		name: "LoanContractDetailDisbursementDetail",
		metaInfo: {
			title: "View Contact Detail - Disbursement - Detail",
		},
		components: { KtvButton, KtvModal, VueCountdown },
		props: {
			contract: {
				default: () => {},
				required: false,
				type: Object,
			},
			paymentMethodOptions: {
				default: () => [],
				required: true,
				type: Array,
			},
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
			to: {
				default: null,
				required: false,
				type: String,
			},
		},
		emits: ["close"],
		data() {
			return {
				accordionAttributes: [],
				expiredDate: null,
				is: {
					loading: false,
					showingModal: false,
				},
				paymentInstructions: [],
				serviceCharge: null,
				virtualAccount: null,
				visible: 0,
			}
		},
		computed: {
			...mapGetters({
				token: "AUTHDATA/token",
			}),
			accountName() {
				if (this.to === "retailer") {
					return this.contract.retailerSupplierAccountHolderName
				}
				if (this.to === "farmer") {
					return this.contract.accountHolder
				}

				return null
			},
			accountNumber() {
				if (this.to === "retailer") {
					return this.contract.retailerSupplierAccountNumber
				}
				if (this.to === "farmer") {
					return this.contract.accountNumber
				}

				return null
			},
			amount() {
				if (this.to === "retailer") {
					return this.contract.loanContractProductAmount
				}
				if (this.to === "farmer") {
					return this.contract.loanContractCashAmount
				}

				return null
			},
			destinationId() {
				if (this.to === "retailer") {
					return this.contract.productDashboard.kioskId
				}
				if (this.to === "farmer") {
					return this.contract.loanEntity
				}

				return null
			},
			destinationName() {
				if (this.to === "retailer") {
					return this.contract.productDashboard.kioskName
				}
				if (this.to === "farmer") {
					return this.contract.dataName
				}

				return null
			},
			destinationType() {
				if (this.to === "retailer") {
					return "Retailer"
				}
				if (this.to === "farmer") {
					return "Farmer"
				}

				return null
			},
			expiredDateInMiliseconds() {
				return moment(this.expiredDate).valueOf() - moment().valueOf()
			},
			isExpired() {
				return moment(this.expiredDate).valueOf() <= moment().valueOf()
			},
			isLoaded() {
				return (this.amount !== null || this.amount !== null) && this.paymentMethodId
			},
			note() {
				if (this.to === "retailer") {
					return "loan-disbursement-product"
				}
				if (this.to === "farmer") {
					return "loan-disbursement-cash"
				}

				return null
			},
			paymentMethodId() {
				if (this.to === "retailer") {
					return this.contract.retailerPaymentMethod
				}
				if (this.to === "farmer") {
					return this.contract.farmerPaymentMethod
				}

				return null
			},
			paymentMethod() {
				if (this.to === "retailer") {
					return findWhere(this.paymentMethodOptions, { value: this.contract.retailerPaymentMethod }).text || null
				}
				if (this.to === "farmer") {
					return findWhere(this.paymentMethodOptions, { value: this.contract.farmerPaymentMethod }).text || null
				}

				return null
			},
			transactionId() {
				if (this.to === "retailer") {
					return `${this.contract.loanContractId}-${this.contract.productDashboard.kioskId}`
				}
				if (this.to === "farmer") {
					return `${this.contract.loanContractId}-${this.contract.loanEntity}`
				}

				return null
			},
		},
		watch: {
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}

					if (show && this.isLoaded) {
						this.serviceCharge = null

						this.checkServiceCharge({
							amount: this.amount,
							paymentMethodId: this.paymentMethodId,
							type: `loan-to-${this.to}`,
						}).then((data) => {
							this.serviceCharge = data

							this.generateVirtualAccount()
						})
					}
				},
			},
			token: {
				handler(newValue, oldValue) {
					if (newValue && newValue !== oldValue && this.show) {
						Promise.all([this.getPaymentReference("loan-to-farmer"), this.getPaymentReference("loan-to-retailer")]).then(() => {
							this.getPaymentReference("loan-to-farmer")
							this.getPaymentReference("loan-to-retailer")
						})
					}
				},
				immediate: true,
			},
		},
		methods: {
			...mapActions({
				checkServiceCharge: "PAYMENT/checkServiceCharge",
				createDisburse: "CONTRACT/createDisburse",
				getPaymentReference: "PAYMENT/getPaymentReference",
				submitPayment: "PAYMENT/submitPayment",
			}),
			close() {
				this.is = {
					...this.is,
					loading: false,
				}

				this.$emit("close", this.to)
			},
			copy(id) {
				let copyText = document.querySelector(id)
				copyText.select()
				document.execCommand("copy")

				this.$swal(this.$t("Text copied to clipboard", "Text copied to clipboard"), String(copyText.value), "info")
			},
			generateVirtualAccount() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.submitPayment({
					accountName: this.accountName,
					accountNumber: this.accountNumber,
					amount: this.amount,
					bankCode: "002", // TO-DO: temp, subject to change
					bankName: "BRI", // TO-DO: temp, subject to change
					destinationId: this.destinationId,
					destinationName: this.destinationName,
					destinationType: this.destinationType,
					id: this.transactionId,
					note: this.note,
					paymentMethodId: this.paymentMethodId,
					serviceCharge: this.serviceCharge.ServiceCharge,
					serviceChargeType: this.serviceCharge.ServiceChargeType,
					totalServiceCharge: this.serviceCharge.TotalServiceCharge,
					type: `loan-to-${this.to}`,
				})
					.then((data) => {
						this.createDisburse({
							disburseAmount: data.totalFee,
							disburseTrxId: this.transactionId,
							disburseType: this.to === "retailer" ? 148 : 147,
							loanContractId: this.$route.params.id,
							paymentMethodId: this.paymentMethodId,
							response: JSON.stringify(data),
						})
							.then(() => {
								this.virtualAccount = data.PaymentDetail[0].CompanyCode + data.PaymentDetail[0].VirtualAccount
								this.paymentInstructions = data.PaymentDetail[0].PaymentInstruction
								this.accordionAttributes = map(data.PaymentDetail[0].PaymentInstruction, (_a, index) => {
									return {
										class: "i-Arrow-Down",
										"v-b-toggle": `'accordion-${index}'`,
									}
								})
								this.expiredDate = data.PaymentDetail[0].ExpiredDate

								this.is = {
									...this.is,
									loading: false,
								}
							})
							.catch(() => {
								this.is = {
									...this.is,
									loading: false,
								}
							})
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			getDate(date) {
				return moment(date).format("MMMM Do YYYY, HH:mm:ss")
			},
			save() {
				this.is = {
					...this.is,
					loading: true,
				}
			},
		},
	}
</script>
