<template>
	<b-tab
		:title="$t('Application Detail', 'Application Detail')"
		:active="activeTab === 'ApplicationDetail'"
		@click="$emit('on-tab-change', 'ApplicationDetail')"
	>
		<!-- Personal Data Section -->
		<b-card
			border-variant="primary"
			:header="$t('Personal Data', 'Personal Data')"
			w
			header-bg-variant="primary"
			header-text-variant="white"
			align="left"
			no-body
			class="mb-2"
		/>
		<form-personal :key="result.length + id" :result="result" />
		<div style="height: 1.25rem" />

		<!-- Family Section -->
		<b-card border-variant="primary" header="Family" header-bg-variant="primary" header-text-variant="white" align="left" no-body class="mb-2" />
		<form-family :key="result.length + id2" :result="result" />
		<div style="height: 1.25rem" />

		<!-- Certification Section -->
		<b-card border-variant="primary" header="Certification" header-bg-variant="primary" header-text-variant="white" align="left" no-body class="mb-2" />
		<form-certified :key="result.length + id3" :result="result" />
		<div style="height: 1.25rem" />

		<!-- Address Section -->
		<b-card border-variant="primary" header="Address" header-bg-variant="primary" header-text-variant="white" align="left" no-body class="mb-2" />
		<form-address :key="result.length + id4" :result="result" />
		<div style="height: 1.25rem" />

		<!-- Contact Section -->
		<b-card border-variant="primary" header="Contact" header-bg-variant="primary" header-text-variant="white" align="left" no-body class="mb-2" />
		<form-contact :key="result.length + id5" :result="result" />
		<div style="height: 1.25rem" />

		<!-- Bank Account Section -->
		<b-card border-variant="primary" header="Bank Account" header-bg-variant="primary" header-text-variant="white" align="left" no-body class="mb-2" />
		<form-bank :key="result.length + id6" :result="result" />

		<div style="height: 20px" />
		<!-- Business Data -->
		<form-businness-data :key="result.length + id7" :result="result" />

		<div style="height: 20px" />
		<!-- Document Data -->
		<form-document :key="result.length + id8" :result="result" />

		<div style="height: 1.25rem" />
		<b-card border-variant="primary" header="Loan Data" header-bg-variant="primary" header-text-variant="white" align="left">
			<form-loan-data :key="result.length + id9" :result="result" />
			<div style="height: 1.25rem" />
			<form-supplier :key="result.length + id11" :result="result" />
		</b-card>
		<!-- end Application Detail-->
	</b-tab>
</template>

<script>
	import FormPersonal from "../../application/form/FormPersonal"
	import FormFamily from "../../application/form/FormFamily"
	import FormCertified from "../../application/form/FormCertified"
	import FormAddress from "../../application/form/FormAddress"
	import FormContact from "../../application/form/FormContact"
	import FormBank from "../../application/form/FormBank"
	import FormSupplier from "../../application/form/FormSupplier"
	import FormDocument from "../../application/form/FormDocument"
	import FormBusinnessData from "../../application/form/FormBusinnessData"
	import FormLoanData from "./../form/FormLoanData"
	import { v4 as uuidv4 } from "uuid"
	import { mapActions, mapGetters } from "vuex"

	const columnsBusinessList = [
		{
			label: "Business",
			field: "BusName",
			thClass: "text-left",
		},
		{
			label: "Status",
			field: "BusStatus",
			thClass: "text-left",
		},
		{
			label: "Size",
			field: "BusSize",
			thClass: "text-left",
		},
		{
			label: "Production",
			field: "BusProduction",
			thClass: "text-left",
		},
		{
			label: "Lat",
			field: "BusLat",
			thClass: "text-left",
		},
		{
			label: "Long",
			field: "BusLong",
			thClass: "text-left",
		},
		{
			label: "Action",
			field: "ButtonAct",
			html: true,
			tdClass: "text-center",
			thClass: "text-center",
			sortable: false,
		},
	]

	export default {
		name: "LoanContractDetailApplicationDetail",
		metaInfo: {
			title: "View Contract Detail - Application Detail",
		},
		components: {
			FormPersonal,
			FormFamily,
			FormCertified,
			FormAddress,
			FormContact,
			FormBank,
			FormLoanData,
			FormSupplier,
			FormDocument,
			FormBusinnessData,
		},
		props: {
			activeTab: {
				default: null,
				required: true,
				type: String,
			},
		},
		emits: ["on-tab-change"],
		data() {
			return {
				id: "",
				id2: "",
				id3: "",
				id4: "",
				id5: "",
				id6: "",
				id7: "",
				id8: "",
				id9: "",
				id10: "",
				id11: "",
				loading: true,
				columnsBusinessList: columnsBusinessList,
				result: [],
			}
		},
		computed: {
			...mapGetters({
				getDataContract: "CONTRACT/getDataContract",
			}),
		},
		watch: {
			activeTab: {
				deep: true,
				handler() {
					if (this.activeTab === "ApplicationDetail") {
						this.FetchData()
					}
				},
				immediate: true,
			},
		},
		beforeMount() {
			this.id = uuidv4()
			this.id2 = uuidv4()
			this.id3 = uuidv4()
			this.id4 = uuidv4()
			this.id5 = uuidv4()
			this.id6 = uuidv4()
			this.id7 = uuidv4()
			this.id8 = uuidv4()
			this.id9 = uuidv4()
			this.id10 = uuidv4()
			this.id11 = uuidv4()
		},
		methods: {
			...mapActions({
				ActionGetDetail: "CONTRACT/ActionGetDetail",
			}),
			async FetchData() {
				this.$store.commit("LOAN/setLoanID", this.$route.params.id)
				this.loading = false
				const res = await this.ActionGetDetail(this.$route.params.id)
				this.result = res
			},
		},
	}
</script>
