<template>
	<div>
		<!-- Loan Packet Section -->
		<b-card
			border-variant="primary"
			:header="$t('Loan Package', 'Loan Package')"
			header-bg-variant="primary"
			header-text-variant="white"
			align="left"
			no-body
			class="mb-2"
		/>
		<vcl-facebook v-show="loading" />
		<b-row v-show="!loading">
			<b-form-group class="col-md-12 mb-2" :label="$t('Loan Package', 'Loan Package')">
				<b-form-input v-model="form.LoanPackage" type="text" readonly />
			</b-form-group>

			<!-- Cash only --->
			<b-form-group v-show="statusLoan" class="col-md-6 mb-2" :label="$t('Cash Loan Request', 'Cash Loan Request')">
				<b-form-input v-model="form.CashLoan" type="text" readonly />
			</b-form-group>
			<b-form-group v-show="statusLoan" class="col-md-6 mb-2" :label="$t('Cash Loan Approved', 'Cash Loan Approved')">
				<b-form-input v-model="form.CashLoanApproved" readonly type="text" />
			</b-form-group>

			<!-- product only --->
			<b-form-group v-show="statusProduct" class="col-md-6 mb-2" :label="$t('Product Loan Request', 'Product Loan Request')">
				<b-form-input v-model="form.CashProduct" type="text" readonly />
			</b-form-group>
			<b-form-group v-show="statusProduct" class="col-md-6 mb-2" :label="$t('Product Loan Approved', 'Product Loan Approved')">
				<b-form-input v-model="form.CashProductApproved" type="text" readonly />
			</b-form-group>
			<!-- end product only --->

			<b-form-group v-show="statusLoan" class="col-md-2 mb-2" :label="$t('Number of Agri Inputs', 'Number of Agri Inputs')">
				<b-form-input v-model="form.NumberOfAgriinput" type="text" readonly />
			</b-form-group>
		</b-row>
		<b-card
			v-show="!loading"
			border-variant="primary"
			:header="$t('Product Detail', 'Product Detail')"
			header-bg-variant="primary"
			header-text-variant="white"
			align="left"
			no-body
			class="mb-2"
		/>
		<b-row v-for="(row, index) in ProductDashboarddetails" :key="index">
			<b-col cols="4">
				<b-img center :src="imgsdata(row.image)" v-bind="propsImageProduct" />
			</b-col>
			<b-col cols="8">
				<b-form>
					<b-form-group class="col-md-12 mb-2" :label="$t('Agri Inputs Name', 'Agri Inputs Name')" label-for="input-1">
						<b-form-input :value="row.name" type="text" readonly />
					</b-form-group>
					<b-row>
						<b-form-group class="col-md-3 mb-2 ml-3" :label="$t('Quantity Request', 'Quantity Request')" label-for="input-1">
							<b-form-input :value="row.quantity" type="text" readonly />
						</b-form-group>
						<b-form-group class="col-md-3 mb-2" :label="$t('Price (/unit)', 'Price (/unit)')" label-for="input-1">
							<b-form-input :value="row.salePrice" type="text" readonly />
						</b-form-group>
					</b-row>
					<b-form-group class="col-md-3 mb-2" :label="$t('Quantity Approved', 'Quantity Approved')" label-for="input-1">
						<b-form-input v-model="row.quantityApprove" type="number" readonly />
					</b-form-group>
				</b-form>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import { mapActions, mapGetters } from "vuex"
	export default {
		props: {
			result: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				loading: true,
				propsImageProduct: { width: 200 },
				ProductDashboarddetails: [],
				List: [],
				form: {
					LoanPackage: "",
					CashLoan: "",
					CashLoanApproved: "",
					CashProduct: "",
					CashProductApproved: "",
					NumberOfAgriinput: "",
					ProductDashboard: [],
				},
				statusLoan: false,
				statusProduct: false,
			}
		},
		computed: {
			...mapGetters({
				loggedInUser: "AUTHDATA/loggedInUser",
				getCashProductApproved: "LOAN/getCashProductApproved",
			}),
		},
		mounted() {
			this.getPocket()
		},
		methods: {
			...mapActions({}),
			imgsdata(image) {
				let img = ""
				if (image !== null) {
					const partsOfStr = image.split(",")
					img = "https://farmretail.s3-ap-southeast-1.amazonaws.com/produk/" + partsOfStr[0]
				}
				return img
			},
			getPocket() {
				/**
				 * Waiting for a render data before result done
				 */

				if (this.result.length > 0) {
					this.loading = false

					this.form.LoanPackage = this.result[0].productDashboard.packageName
					this.form.CashLoan = this.formatPrice(this.result[0].loanCashAmount)
					this.form.CashProduct = this.formatPrice(this.result[0].loanProductAmount)
					this.form.NumberOfAgriinput = this.result[0].productDashboard.count
					this.form.CashProductApproved = this.formatPrice(this.result[0].loanContractProductAmount)
					this.form.CashLoanApproved = this.formatPrice(this.result[0].loanCashAmountApprove)

					if (Object.keys(this.result[0].productDashboard).length > 0) {
						// Product pocket
						if (this.result[0].productDashboard.detail.length > 0) {
							let arrProd = []
							this.result[0].productDashboard.detail.forEach((e) => {
								arrProd.push({
									name: e.name,
									image: e.image,
									quantity: e.quantity,
									salePrice: this.formatPrice(e.salePrice),
									quantityApprove: e.quantityApprove === 0 ? e.quantity : e.quantityApprove,
								})
							})

							this.ProductDashboarddetails = arrProd

							if (this.result[0].productDashboard.loanPackageTypeId === 156 || this.result[0].productDashboard.loanPackageTypeId === 157) {
								this.statusProduct = true
							}
						}
						// Cash pocket
						if (this.result[0].productDashboard.loanPackageTypeId === 155 || this.result[0].productDashboard.loanPackageTypeId === 157) {
							this.statusLoan = true
						}
					}
				}
			},
			/* Handler Calculate Repayment Amount */
			onCalculateRepaymentAmount() {},
			formatPrice(value) {
				let val = (value / 1).toFixed().replace(".", ",")
				return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
			},
		},
	}
</script>
