<template>
	<b-tab :title="$t('Loan Contract', 'Loan Contract')" :active="activeTab === 'LoanContract'" @click="$emit('on-tab-change', 'LoanContract')">
		<vue-html2pdf
			ref="contractAgreement"
			:enable-download="true"
			:filename="`${$t('Loan Contract Agreement', 'Loan Contract Agreement')} (${loanContractNumberAgreement})`"
			:float-layout="true"
			:manual-pagination="true"
			:paginate-elements-by-height="1400"
			:pdf-quality="2"
			:preview-modal="false"
			:show-layout="false"
			pdf-content-width="100%"
			pdf-format="a4"
			pdf-orientation="portrait"
		>
			<section slot="pdf-content">
				<agreement-conventional v-if="agreement && agreement.typeOfLoan === 'Konvensional'" :id="loanContractNumberAgreement" :agreement="agreement" />
				<agreement-syaria v-else-if="agreement && agreement.typeOfLoan === 'Syariah'" :id="loanContractNumberAgreement" :agreement="agreement" />
			</section>
		</vue-html2pdf>
		<b-card
			border-variant="primary"
			:header="$t('Upload Loan Contract', 'Upload Loan Contract')"
			header-bg-variant="primary"
			header-text-variant="white"
			align="left"
			no-body
			class="mb-2"
		/>
		<vcl-facebook v-show="loadings" />
		<b-form v-show="!loadings">
			<div class="d-flex" style="align-items: center">
				<b-form-group class="col-md-5 mb-2" :label="$t('Number of Agreement', 'Number of Agreement')">
					<b-form-input
						v-model="loanContractNumberAgreement"
						v-validate="'required'"
						:state="validateState('loanContractNumberAgreement')"
						data-vv-name="loanContractNumberAgreement"
						type="text"
						style="background: white"
					/>
				</b-form-group>
				<ktv-button v-show="!loadings" :text="$t('Save Number', 'Save Number')" color="primary" size="sm" class="ml-2 mt-3" @click="saveNumber" />
				<ktv-button
					:disabled="loadings || !loanContractNumberAgreement || loanContractNumberAgreement !== loanContractNumberAgreementOriginal"
					:loading="loadings"
					:text="$t('Download Contract Agreement', 'Download Contract Agreement')"
					class="ml-2 mt-3"
					color="light"
					icon="download"
					size="sm"
					@click="downloadContractAgreement"
				/>
			</div>
			<div class="d-flex" style="align-items: center">
				<b-form-group class="col-md-5 mb-2" :label="$t('Loan Agreement', 'Loan Agreement')">
					<b-form-file
						ref="file"
						v-model="fileReceipt"
						:disabled="loadings || !loanContractNumberAgreement || loanContractNumberAgreement !== loanContractNumberAgreementOriginal"
						:state="validateState('fileReceipt')"
						data-vv-name="fileReceipt"
						style="background: white"
						@change="onFileChange"
					/>
				</b-form-group>
				<ktv-button
					:disabled="loadings || !loanContractNumberAgreement || loanContractNumberAgreement !== loanContractNumberAgreementOriginal || !fileReceipt"
					:text="$t('Upload Signed Agreement', 'Upload Signed Agreement')"
					color="primary"
					size="sm"
					class="ml-2 mt-3"
					@click="uploadFile"
				/>
				<ktv-button
					:disabled="
						loadings ||
							!loanContractNumberAgreement ||
							loanContractNumberAgreement !== loanContractNumberAgreementOriginal ||
							!loanContractImagesAgreement
					"
					:loading="loadings"
					:text="$t('Download Signed Contract Agreement', 'Download Signed Contract Agreement')"
					class="ml-2 mt-3"
					color="light"
					icon="download"
					size="sm"
					@click="downloadSignedContractAgreement"
				/>
			</div>
		</b-form>
	</b-tab>
</template>

<script>
	import { KtvButton } from "@/components"
	import { mapActions, mapGetters } from "vuex"

	import AgreementConventional from "./agreement/_Conventional"
	import AgreementSyaria from "./agreement/_Syaria"
	import VueHtml2pdf from "vue-html2pdf"

	export default {
		name: "LoanContractDetailLoanContract",
		metaInfo: {
			title: "View Contact Detail - Loan Contract",
		},
		components: { AgreementConventional, AgreementSyaria, KtvButton, VueHtml2pdf },
		props: {
			activeTab: {
				default: null,
				required: true,
				type: String,
			},
		},
		emits: ["on-tab-change"],
		data() {
			return {
				agreement: null,
				loadings: true,
				loanContractNumberAgreement: null,
				loanContractNumberAgreementOriginal: null,
				loanContractImagesAgreement: "#",
				fileReceipt: null,
			}
		},
		computed: {
			...mapGetters({
				loggedInUser: "AUTHDATA/loggedInUser",
			}),
		},
		watch: {
			activeTab: {
				deep: true,
				handler() {
					if (this.activeTab === "LoanContract") {
						this.getData().then(() => {
							this.loadings = true

							this.getContractAgreement(this.$route.params.id)
								.then((agreement) => {
									this.agreement = Object.assign({}, agreement)
									this.loadings = false
								})
								.catch(() => {
									this.loadings = false
								})
						})
					}
				},
				immediate: true,
			},
		},
		methods: {
			...mapActions({
				ActionUploadS3: "CONTRACT/ActionUploadS3",
				ActionDocumentContractSave: "CONTRACT/ActionDocumentContractSave",
				ActionGetDetail: "CONTRACT/ActionGetDetail",
				getContractAgreement: "CONTRACT/getContractAgreement",
			}),
			downloadContractAgreement() {
				this.$refs.contractAgreement.generatePdf()
			},
			downloadFile(url, fileName) {
				fetch(url)
					.then((response) => response.blob())
					.then((blob) => {
						const link = document.createElement("a")
						link.href = URL.createObjectURL(blob)
						link.download = fileName
						link.click()
					})
					.catch((error) => {
						const err = error.toJSON()
						const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

						this.$swal(err.name, errorMessage, "error")
					})
			},
			downloadSignedContractAgreement() {
				const fileExtension = this.loanContractImagesAgreement.split(".").pop()

				const replacedFileName = `${this.$t("Loan Contract Agreement", "Loan Contract Agreement")} (${this.loanContractNumberAgreement}) - ${this.$t(
					"Signed",
					"Signed",
				)}.${fileExtension}`
				this.downloadFile(this.loanContractImagesAgreement, replacedFileName)

				this.$emit("on-tab-change", "Disbursement")
			},
			async getData() {
				this.loading = true
				const res = await this.ActionGetDetail(this.$route.params.id)
				this.loanContractNumberAgreement = res[0].loanContractNumberAgreement
				this.loanContractNumberAgreementOriginal = res[0].loanContractNumberAgreement
				this.loanContractImagesAgreement = res[0].loanContractImagesAgreement
				this.loadings = false
			},
			onFileChange() {
				this.fileReceipt = this.$refs.file.files[0]
			},
			saveNumber() {
				this.$validator.validateAll().then((result) => {
					this.loadings = true
					if (!result) {
						this.loadings = false
						return
					}

					this.ActionDocumentContractSave({
						loanContractId: this.$route.params.id,
						loanContractNumberAgreement: this.loanContractNumberAgreement,
						createdBy: this.loggedInUser.UserId,
						documents: [],
					}).then((res) => {
						if (res === "Success") {
							this.getData().then(() => {
								this.loadings = false
							})
						} else {
							this.loadings = false
						}
					})
				})
			},
			uploadFile() {
				this.$validator.validateAll().then((result) => {
					if (!result) {
						return
					}

					const formData = new FormData()
					formData.append("file_data", this.fileReceipt)
					formData.append("bucket_name", "koltiva-loan")
					formData.append("s3_dest_path", "loan/contract/")
					this.loadings = true
					this.ActionUploadS3(formData).then((response) => {
						const payload = {
							loanContractId: this.$route.params.id,
							loanContractNumberAgreement: this.loanContractNumberAgreement,
							createdBy: this.loggedInUser.UserId,
							documents: [
								{
									documentUrl: response.s3_url,
									documentInfo: response.file_name,
								},
							],
						}
						this.ActionDocumentContractSave(payload).then(() => {
							this.getData()
								.then(() => {
									this.fileReceipt = null
									this.loadings = false
								})
								.catch(() => {
									this.fileReceipt = null
									this.loadings = false
								})
						})
					})
				})
			},
		},
	}
</script>
