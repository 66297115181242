<template>
	<b-tab :title="$t('Repayment', 'Repayment')" :active="activeTab === 'Repayment'" @click="$emit('on-tab-change', 'Repayment')">
		<repayment-add-modal :show="is.showing.addModal" @close="closeAddModal" />
		<b-button v-show="!loadingGrids" variant="success" :disabled="isDisabledButton" @click="showAddModal()">
			{{ $t("Add Applicant Repayment", "Add Applicant Repayment") }}
		</b-button>
		<div style="height: 1.25rem" />
		<b-card
			border-variant="primary"
			:header="$t('Repayment', 'Repayment')"
			header-bg-variant="primary"
			header-text-variant="white"
			align="left"
			no-body
			class="mb-2"
		/>
		<vcl-facebook v-show="loading" />
		<b-form v-show="!loading">
			<b-row>
				<b-form-group class="col-md-6 mb-2" :label="$t('Amount of Repayment', 'Amount of Repayment')">
					<b-form-input v-model="repay.amountRepayment" type="text" readonly />
				</b-form-group>
				<b-form-group class="col-md-6 mb-2" :label="$t('Repayment has been Paid', 'Repayment has been Paid')">
					<b-form-input v-model="repay.amountPaid" type="text" readonly />
				</b-form-group>
				<b-form-group class="col-md-6 mb-2" :label="$t('Tenor', 'Tenor')">
					<b-form-input v-model="repay.tenor" type="text" readonly />
				</b-form-group>
				<b-form-group class="col-md-6 mb-2" :label="$t('Terms of Payment', 'Terms of Payment')">
					<b-form-input v-model="repay.termsOfPayment" type="text" readonly />
				</b-form-group>
			</b-row>
		</b-form>
		<div style="height: 1.25rem" />
		<b-card
			border-variant="primary"
			:header="$t('Repayment History', 'Repayment History')"
			header-bg-variant="primary"
			header-text-variant="white"
			align="left"
			no-body
			class="mb-2"
		/>
		<vcl-facebook v-show="loadingGrids" />
		<ktv-table
			v-show="!loadingGrids"
			:fixed-header="true"
			:columns="columnsRepayList"
			:line-numbers="true"
			:rows="form.rowsRepayList"
			:search-enabled="false"
			:filter="false"
			:search-custom-enabled="true"
		>
			<template #columns="{ props }">
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field === 'installmentAmount'"> {{ formatPrice(props.row.installmentAmount) }} </span>
				<span v-if="props.column.field === 'installmentDueDate'"> {{ props.row.installmentDueDate }} </span>
				<span v-if="props.column.field === 'payment'">
					<span v-for="(rows, index) in props.row.payment" :key="index">
						{{ formatPrice(rows.installmentAmountPaid) }}
						<hr v-show="props.row.payment.length > 1 ? true : false" style="margin: 3px" />
					</span>
				</span>

				<span v-if="props.column.field === 'installmentDatePaid'">
					<span v-for="(rows, index) in props.row.payment" :key="index">
						{{ rows.installmentDatePaid }}
						<hr v-show="props.row.payment.length > 1 ? true : false" style="margin: 3px" />
					</span>
				</span>

				<span v-if="props.column.field === 'repaymentMethodDesc'">
					<span v-for="(rows, index) in props.row.payment" :key="index">
						{{ rows.repaymentMethodDesc === null ? "-" : rows.repaymentMethodDesc }}
						<hr v-show="props.row.payment.length > 1 ? true : false" style="margin: 3px" />
					</span>
				</span>
				<span v-if="props.column.field === 'overallStatusDesc'" :class="props.row.overallStatusId !== 231 ? 'text-danger' : 'text-primary'">
					{{ props.row.overallStatusDesc }}
				</span>
			</template>
		</ktv-table>
		<div style="height: 1.25rem" />
		<b-card
			border-variant="primary"
			:header="$t('Payment Receipt', 'Payment Receipt')"
			header-bg-variant="primary"
			header-text-variant="white"
			align="left"
			no-body
			class="mb-2"
		>
			<vcl-facebook v-show="loadingDocument" />
			<b-row v-show="!loadingDocument">
				<b-col v-for="(r, index) in document" :key="index" cols="3">
					<center>
						<b-link :href="r.documentUrl">{{ r.documentInfo }}</b-link>
					</center>
					<b-img center :src="r.documentUrl" class="mb-2 col-md-6" />
				</b-col>
			</b-row>
			<b-card-footer class="accor-footer">
				<b-button v-show="!loadingGrids" variant="success" class="ml-2 float-right" :disabled="isCloseContractButton" @click="closeContract">
					{{ $t("Close Contract", "Close Contract") }}
				</b-button>
			</b-card-footer>
		</b-card>
	</b-tab>
</template>

<script>
	import { KtvTable } from "@/components"
	import RepaymentAddModal from "./modals/_RepaymentAdd"
	import { mapActions, mapGetters } from "vuex"
	export default {
		name: "LoanContractDetailRepayment",
		metaInfo: {
			title: "View Contact Detail - Repayment",
		},
		components: { KtvTable, RepaymentAddModal },
		props: {
			activeTab: {
				default: null,
				required: true,
				type: String,
			},
		},
		emits: ["on-tab-change"],
		data() {
			return {
				isDisabledButton: false,
				isCloseContractButton: true,
				columnsRepayList: [
					{
						label: "Amount",
						field: "installmentAmount",
						thClass: "text-left",
						sortable: false,
					},
					{
						label: "Due Date",
						field: "installmentDueDate",
						thClass: "text-left",
						sortable: false,
					},
					{
						label: "Payment",
						field: "payment",
						thClass: "text-left",
						sortable: false,
					},
					{
						label: "Payment Date",
						field: "installmentDatePaid",
						thClass: "text-left",
						sortable: false,
					},
					{
						label: "Payment Method",
						field: "repaymentMethodDesc",
						thClass: "text-left",
						sortable: false,
					},
					{
						label: "Status",
						field: "overallStatusDesc",
						thClass: "text-left",
						sortable: false,
					},
				],
				form: {},
				loading: true,
				loadingGrids: true,
				loadingDocument: true,
				is: {
					showing: {
						addModal: false,
					},
				},
				repay: {
					amountRepayment: "",
					ampuntPaid: "",
					tenor: "",
					termsOfPayment: "",
				},
				document: [],
			}
		},
		computed: {
			...mapGetters({
				loggedInUser: "AUTHDATA/loggedInUser",
				getDataContract: "CONTRACT/getDataContract",
			}),
		},
		watch: {
			activeTab: {
				deep: true,
				handler() {
					if (this.activeTab === "Repayment") {
						this.onMounted()
					}
				},
				immediate: true,
			},
		},
		methods: {
			...mapActions({
				ActionGetDetail: "CONTRACT/ActionGetRepayment",
				ActionCloseContractSave: "CONTRACT/ActionCloseContractSave",
			}),
			closeAddModal() {
				this.is.showing = {
					...this.is.showing,
					addModal: false,
				}
			},
			async onMounted() {
				await this.fetchData()
				if (this.getDataContract.statusDesc === "closed") {
					this.isCloseContractButton = true
				} else {
					this.isCloseContractButton = false
				}
			},
			showAddModal() {
				this.is.showing = {
					...this.is.showing,
					addModal: true,
				}
			},
			async fetchData() {
				const res = await this.ActionGetDetail(this.$route.params.id)
				if (res.length > 0) {
					this.loading = false
					this.loadingGrids = false
					this.loadingDocument = false
					this.form.rowsRepayList = res[0].loanInstallmentPayments
					this.repay.amountRepayment = this.formatPrice(res[0].totalAmount)
					this.repay.amountPaid = this.formatPrice(res[0].amountPaid)
					this.repay.tenor = res[0].tenor
					this.repay.termsOfPayment = res[0].numberOfInstallment
					this.document = res[0].documents
					if (parseFloat(res[0].totalAmount) === parseFloat(res[0].amountPaid) || parseFloat(res[0].totalAmount) < parseFloat(res[0].amountPaid)) {
						this.isDisabledButton = true
						this.isCloseContractButton = false
					} else {
						this.isDisabledButton = false
						this.isCloseContractButton = true
					}
				}
			},
			formatPrice(value) {
				let val = (value / 1).toFixed().replace(".", ",")
				return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
			},
			recalculated(amountRepayment) {
				let hitungAmountRepayment = 0
				const repaymentamount = this.repay.amountRepayment.replace(/\./g, "")
				const amountpaid = this.repay.amountPaid.replace(/\./g, "")
				const jumlahbayar = amountRepayment.replace(/\./g, "")
				hitungAmountRepayment = parseFloat(amountpaid) + parseFloat(jumlahbayar)
				this.repay.amountPaid = this.formatPrice(hitungAmountRepayment)

				if (parseFloat(repaymentamount) === parseFloat(amountpaid) || parseFloat(repaymentamount) < parseFloat(amountpaid)) {
					this.isDisabledButton = true
				} else {
					this.isDisabledButton = false
				}
				this.loadingGrids = true
				this.loadingDocument = true
				this.fetchData()
			},
			closeContract() {
				const payload = {
					loanContractId: this.$route.params.id,
					createdBy: this.loggedInUser.UserId,
					status: "closed",
				}

				this.$swal({
					type: "warning",
					title: this.$t("Confirmation", "Confirmation"),
					text: this.$t("Are you sure you want to close the contract data?", "Are you sure you want to close the contract data?"),
					showConfirmButton: true,
					showCancelButton: true,
					confirmButtonText: this.$t("Submit", "Submit"),
					cancelButtonText: this.$t("Cancel", "Cancel"),
				}).then((isConfirm) => {
					if (isConfirm.value === true) {
						this.$swal.fire({
							title: `${this.$t("Loading", "Loading")}...`,
							html: this.$t("Data will be saved", "Data will be saved"),
							allowOutsideClick: false,
							onBeforeOpen: () => {
								this.$swal.showLoading()
							},
						})
						this.ActionCloseContractSave(payload).then((e) => {
							if (e === "Success") {
								this.isCloseContractButton = true
							}
						})
					}
				})
			},
		},
	}
</script>
<style>
	.tab-content .vgt-global-search.vgt-clearfix {
		padding: 0 14px;
		border-radius: 4px;
		height: 0px;
	}
	.accor-footer {
		padding: 16px;
		background-color: #f5f5f5;
	}
</style>
