<template>
	<b-tab :title="$t('Disbursement', 'Disbursement')" :active="activeTab === 'Disbursement'" @click="$emit('on-tab-change', 'Disbursement')">
		<disbursement-detail-modal
			:contract="localContract"
			:payment-method-options="paymentMethodOptions[disburseTo]"
			:to="disburseTo"
			:show="is.showing.detailModal"
			@close="closeDetailModal"
		/>
		<payment-instruction
			:contract="localContract"
			:payment-method-options="paymentMethodOptions[disburseTo]"
			:to="disburseTo"
			:show="is.showing.paymentInstruction"
			@close="closePaymentInstruction"
		/>
		<b-row>
			<b-col v-if="localContract.loanContractProductAmount && localContract.loanContractProductAmount > 0">
				<b-card
					border-variant="primary"
					:header="$t('Disburse to Retailer', 'Disburse to Retailer')"
					header-bg-variant="primary"
					header-text-variant="white"
					align="left"
					class="mb-2"
				>
					<b-form>
						<b-row>
							<b-form-group class="col-md-6 mb-2" :label="capitalize($t('Contract ID', 'Contract ID'))">
								<b-form-input v-model="localContract.loanContractId" type="text" readonly />
							</b-form-group>
							<b-form-group class="col-md-6 mb-2" :label="$t('Loan Package', 'Loan Package')">
								<b-form-input v-model="localContract.productDashboard.packageName" type="text" readonly />
							</b-form-group>
							<b-form-group class="col-md-6 mb-2" :label="$t('Amount', 'Amount')">
								<b-form-input :value="idrFilter(localContract.loanContractProductAmount)" type="text" readonly />
							</b-form-group>
							<b-form-group class="col-md-6 mb-2" :label="$t('Retailer Name', 'Retailer Name')">
								<b-form-input v-model="localContract.productDashboard.kioskName" type="text" readonly />
							</b-form-group>
							<b-form-group
								class="mb-2"
								:class="localContract.retailerPayment && localContract.retailerPayment.PaymentStatus ? 'col-md-6' : 'col-md-12'"
								:label="$t('Payment Method', 'Payment Method')"
							>
								<b-form-select
									v-model="localContract.retailerPaymentMethod"
									:disabled="!!retailerVirtualAccount || is.loading || is.checkingPaymentStatus"
									:options="paymentMethodOptions.retailer"
								/>
							</b-form-group>
							<b-form-group
								v-if="localContract.retailerPayment && localContract.retailerPayment.PaymentStatus"
								class="col-md-6 mb-2"
								:label="$t('Status', 'Status')"
							>
								<b-input-group size="md" class="mb-2">
									<b-form-input
										:value="getExpiredStatus(localContract.retailerPayment.PaymentStatus, localContract.retailerPayment.PaymentDetail[0])"
										type="text"
										readonly
									/>
									<b-input-group-prepend
										v-if="retailerVirtualAccount"
										is-text
										style="height: 34px"
										@click="showPaymentInstruction('retailer')"
									>
										<b-icon icon="info" />
									</b-input-group-prepend>
									<b-input-group-prepend is-text style="height: 34px" @click="checkPaymentStatus('retailer')">
										<b-icon icon="arrow-clockwise" />
									</b-input-group-prepend>
								</b-input-group>
							</b-form-group>
							<b-form-group
								v-if="localContract.retailerPayment && localContract.retailerPayment.uid"
								class="col-md-6 mb-2"
								:label="$t('Transaction ID', 'Transaction ID')"
							>
								<b-form-input v-model="localContract.retailerPayment.uid" type="text" readonly />
							</b-form-group>
							<b-form-group v-if="retailerVirtualAccount" class="col-md-6 mb-2" :label="$t('Virtual Account Number', 'Virtual Account Number')">
								<b-form-input v-model="retailerVirtualAccount" type="text" readonly />
							</b-form-group>
							<b-form-group class="col-md-12 mb-2 mt-1 text-right" label="">
								<b-link
									href="#"
									style="text-decoration: underline"
									:disabled="is.loading || is.checkingPaymentStatus"
									@click="checkOrderStatus($route.params.id)"
								>
									{{ $t("Check Order Status", "Check Order Status") }}
								</b-link>
								<b-button class="ml-2" variant="primary" :disabled="is.loading || is.checkingPaymentStatus" @click="sendOrderToRetailer()">
									{{ $t("Send Order to Retailer", "Send Order to Retailer") }}
								</b-button>
								<b-button
									v-if="!retailerVirtualAccount"
									class="ml-2"
									:disabled="!localContract.retailerPaymentMethod || is.loading || is.checkingPaymentStatus"
									variant="primary"
									@click="showDetailModal('retailer')"
								>
									{{ $t("Disburse", "Disburse") }}
								</b-button>
							</b-form-group>
						</b-row>
					</b-form>
				</b-card>
			</b-col>
			<b-col v-if="localContract.loanContractCashAmount && localContract.loanContractCashAmount > 0">
				<b-card
					border-variant="primary"
					:header="$t('Disburse to Farmer', 'Disburse to Farmer')"
					header-bg-variant="primary"
					header-text-variant="white"
					align="left"
					class="mb-2"
				>
					<b-form>
						<b-row>
							<b-form-group class="col-md-6 mb-2" :label="capitalize($t('Contract ID', 'Contract ID'))">
								<b-form-input v-model="localContract.loanContractId" type="text" readonly />
							</b-form-group>
							<b-form-group class="col-md-6 mb-2" :label="$t('Loan Package', 'Loan Package')">
								<b-form-input v-model="localContract.productDashboard.packageName" type="text" readonly />
							</b-form-group>
							<b-form-group class="col-md-6 mb-2" :label="$t('Amount', 'Amount')">
								<b-form-input :value="idrFilter(localContract.loanContractCashAmount)" type="text" readonly />
							</b-form-group>
							<b-form-group class="col-md-6 mb-2" :label="$t('Farmer Name', 'Farmer Name')">
								<b-form-input v-model="localContract.dataName" type="text" readonly />
							</b-form-group>
							<b-form-group
								class="mb-2"
								:class="localContract.farmerPayment && localContract.farmerPayment.PaymentStatus ? 'col-md-6' : 'col-md-12'"
								:label="$t('Payment Method', 'Payment Method')"
							>
								<b-form-select
									v-model="localContract.farmerPaymentMethod"
									:disabled="!!farmerVirtualAccount || is.loading"
									:options="paymentMethodOptions.farmer"
								/>
							</b-form-group>
							<b-form-group
								v-if="localContract.farmerPayment && localContract.farmerPayment.PaymentStatus"
								class="col-md-6 mb-2"
								:label="$t('Status', 'Status')"
							>
								<b-input-group size="md" class="mb-2">
									<b-form-input
										:value="getExpiredStatus(localContract.farmerPayment.PaymentStatus, localContract.farmerPayment.PaymentDetail[0])"
										type="text"
										readonly
									/>
									<b-input-group-prepend v-if="farmerVirtualAccount" is-text style="height: 34px" @click="showPaymentInstruction('farmer')">
										<b-icon icon="info" />
									</b-input-group-prepend>
									<b-input-group-prepend is-text style="height: 34px" @click="checkPaymentStatus('farmer')">
										<b-icon icon="arrow-clockwise" />
									</b-input-group-prepend>
								</b-input-group>
							</b-form-group>
							<b-form-group
								v-if="localContract.farmerPayment && localContract.farmerPayment.uid"
								class="col-md-6 mb-2"
								:label="$t('Transaction ID', 'Transaction ID')"
							>
								<b-form-input v-model="localContract.farmerPayment.uid" type="text" readonly />
							</b-form-group>
							<b-form-group v-if="farmerVirtualAccount" class="col-md-6 mb-2" :label="$t('Virtual Account Number', 'Virtual Account Number')">
								<b-form-input v-model="farmerVirtualAccount" type="text" readonly />
							</b-form-group>
							<b-form-group class="col-md-12 mb-2 mt-2 text-right" label="">
								<b-button
									v-if="!farmerVirtualAccount"
									class="ml-2"
									:disabled="!localContract.farmerPaymentMethod || is.loading"
									variant="primary"
									@click="showDetailModal('farmer')"
								>
									{{ $t("Disburse", "Disburse") }}
								</b-button>
							</b-form-group>
						</b-row>
					</b-form>
				</b-card>
			</b-col>
		</b-row>
		<div style="height: 1.25rem" />
	</b-tab>
</template>

<script>
	import { each, findWhere, map } from "underscore"
	import { mapActions, mapGetters, mapState } from "vuex"
	import DisbursementDetailModal from "./modals/_DisbursementDetail"
	import PaymentInstruction from "./modals/_PaymentInstruction"
	import moment from "moment"

	export default {
		name: "LoanContractDetailDisbursement",
		metaInfo: {
			title: "View Contact Detail - Disbursement",
		},
		components: { DisbursementDetailModal, PaymentInstruction },
		props: {
			activeTab: {
				default: null,
				required: true,
				type: String,
			},
		},
		emits: ["on-tab-change"],
		data() {
			return {
				disburseTo: null,
				is: {
					checkingPaymentStatus: false,
					loading: false,
					showing: {
						detailModal: false,
						paymentInstruction: false,
					},
				},
				localContract: null,
				paymentMethodOptions: {
					farmer: [],
					retailer: [],
				},
			}
		},
		computed: {
			...mapGetters({
				token: "AUTHDATA/token",
			}),
			...mapState("CONTRACT", ["contract"]),
			farmerVirtualAccount() {
				if (
					this.localContract.farmerPayment &&
					this.localContract.farmerPayment.PaymentDetail &&
					this.localContract.farmerPayment.PaymentDetail.length > 0 &&
					this.localContract.farmerPayment.PaymentDetail[0].CompanyCode &&
					this.localContract.farmerPayment.PaymentDetail[0].VirtualAccount
				) {
					return this.localContract.farmerPayment.PaymentDetail[0].CompanyCode + this.localContract.farmerPayment.PaymentDetail[0].VirtualAccount
				}
				return null
			},
			paymentMethodList() {
				const paymentMethods = process.env.VUE_APP_PAYMENT_METHOD_OPTIONS
				const paymentMethodArray = paymentMethods.split(";")

				const paymentMethodList = map(paymentMethodArray, (paymentMethod) => {
					let paymentMethodObject = null

					each(paymentMethod.split(","), (paymentMethodProperties) => {
						const [key, value] = paymentMethodProperties.split(":")

						paymentMethodObject = {
							...paymentMethodObject,
							[key]: key === "PaymentGroupID" || key === "PaymentMethodID" ? parseInt(value, 10) : value,
						}
					})

					return paymentMethodObject
				})

				return paymentMethodList || []
			},
			retailerVirtualAccount() {
				if (
					this.localContract.retailerPayment &&
					this.localContract.retailerPayment.PaymentDetail &&
					this.localContract.retailerPayment.PaymentDetail.length > 0 &&
					this.localContract.retailerPayment.PaymentDetail[0].CompanyCode &&
					this.localContract.retailerPayment.PaymentDetail[0].VirtualAccount
				) {
					return this.localContract.retailerPayment.PaymentDetail[0].CompanyCode + this.localContract.retailerPayment.PaymentDetail[0].VirtualAccount
				}
				return null
			},
		},
		watch: {
			activeTab: {
				handler() {
					if (this.activeTab === "Disbursement") {
						this.checkPaymentStatus("farmer", false)
						this.checkPaymentStatus("retailer", false)
					}
				},
				immediate: true,
			},
			contract: {
				deep: true,
				handler() {
					this.localContract = {
						...this.localContract,
						...this.contract,
						farmerPaymentMethod: 2,
						retailerPaymentMethod: 2,
					}

					this.checkPaymentStatus("farmer", false)
					this.checkPaymentStatus("retailer", false)
				},
				immediate: true,
			},
			paymentMethodList: {
				deep: true,
				handler() {
					this.getPaymentMethodOptions()
				},
				immediate: true,
			},
			token: {
				handler(newValue, oldValue) {
					if (newValue && newValue !== oldValue && this.activeTab === "Disbursement") {
						Promise.all([this.getPaymentReference("loan-to-farmer"), this.getPaymentReference("loan-to-retailer")]).then(() => {
							this.getPaymentReference("loan-to-farmer")
							this.getPaymentReference("loan-to-retailer")
						})
					}
				},
				immediate: true,
			},
		},
		beforeDestroy() {
			this.resetContract()
		},
		created() {
			this.getData()
		},
		methods: {
			...mapActions({
				checkOrderStatus: "CONTRACT/checkOrderStatus",
				getContract: "CONTRACT/getContract",
				getPaymentReference: "PAYMENT/getPaymentReference",
				getPaymentStatus: "PAYMENT/getPaymentStatus",
				resetContract: "CONTRACT/resetContract",
				sendToRetailer: "CONTRACT/sendToRetailer",
			}),
			capitalize(text) {
				if (!text) {
					return ""
				}

				let arr = []
				let exludeWords = ["of", "the", "by", "with"]
				arr = text.split(" ")

				return arr
					.map((word, i) => {
						return exludeWords.includes(word) && i !== 0 ? [word] : word.charAt(0).toUpperCase() + word.slice(1)
					})
					.join(" ")
			},
			checkPaymentStatus(userType, showModal = true) {
				this.is = {
					...this.is,
					checkingPaymentStatus: true,
					loading: true,
				}

				if (userType === "farmer") {
					if (this.localContract?.loanContractId && this.localContract.farmerPaymentMethod && this.localContract?.loanEntity) {
						this.getPaymentStatus({
							paymentMethodId: this.localContract.farmerPaymentMethod,
							type: "loan-to-farmer",
							uid: `${this.localContract.loanContractId}-${this.localContract.loanEntity}`,
						}).then((data) => {
							this.localContract = {
								...this.localContract,
								farmerPayment: data,
							}
							this.is = {
								...this.is,
								checkingPaymentStatus: false,
								loading: false,
							}

							if (showModal && data.PaymentStatus) {
								this.$swal(
									this.$t("Payment Status", "Payment Status"),
									this.getExpiredStatus(data.PaymentStatus, data.PaymentDetail[0]),
									"info",
								)
							}
						})
					}
				} else if (userType === "retailer") {
					if (this.localContract?.loanContractId && this.localContract.retailerPaymentMethod && this.localContract?.productDashboard.kioskId) {
						this.getPaymentStatus({
							paymentMethodId: this.localContract.retailerPaymentMethod,
							type: "loan-to-retailer",
							uid: `${this.localContract.loanContractId}-${this.localContract.productDashboard.kioskId}`,
						}).then((data) => {
							this.localContract = {
								...this.localContract,
								retailerPayment: data,
							}
							this.is = {
								...this.is,
								checkingPaymentStatus: false,
								loading: false,
							}

							if (showModal && data.PaymentStatus) {
								this.$swal(
									this.$t("Payment Status", "Payment Status"),
									this.getExpiredStatus(data.PaymentStatus, data.PaymentDetail[0]),
									"info",
								)
							}
						})
					}
				}
			},
			closeDetailModal(userType) {
				this.checkPaymentStatus(userType, false)
				this.is.showing = {
					...this.is.showing,
					detailModal: false,
				}
			},
			closePaymentInstruction(userType) {
				this.checkPaymentStatus(userType, false)
				this.is.showing = {
					...this.is.showing,
					paymentInstruction: false,
				}
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getContract(this.$route.params.id)
					.then(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			getExpiredStatus(status, { ExpiredDate }) {
				const isExpired = moment(ExpiredDate).valueOf() <= moment().valueOf()

				return isExpired ? this.$t("Expired", "Expired") : status
			},
			getPaymentMethodOptions() {
				this.paymentMethodOptions = {
					...this.paymentMethodOptions,
					farmer: map(this.paymentMethodList, (paymentMethod) => {
						return {
							text: `${paymentMethod.PaymentLabel} - ${paymentMethod.PaymentMethod}`,
							value: parseInt(paymentMethod.PaymentMethodID, 10),
						}
					}),
					retailer: map(this.paymentMethodList, (paymentMethod) => {
						return {
							text: `${paymentMethod.PaymentLabel} - ${paymentMethod.PaymentMethod}`,
							value: parseInt(paymentMethod.PaymentMethodID, 10),
						}
					}),
				}
			},
			idrFilter(amount) {
				return this.$options.filters.Number(amount)
			},
			sendOrderToRetailer() {
				const paymentMethod = findWhere(this.paymentMethodList, { PaymentMethodID: this.localContract.retailerPaymentMethod })

				if (paymentMethod) {
					this.sendToRetailer({
						loanContractId: this.$route.params.id,
						paymentMethod: paymentMethod.PaymentMethod,
					})
				} else {
					this.$swal(
						this.$t("Unable to send order to retailer", "Unable to send order to retailer"),
						this.$t("Payment method can't be empty", "Payment method can't be empty"),
						"error",
					)
				}
			},
			showDetailModal(disburseTo = null) {
				this.is.showing = {
					...this.is.showing,
					detailModal: true,
				}

				this.disburseTo = disburseTo
			},
			showPaymentInstruction(disburseTo = null) {
				this.is.showing = {
					...this.is.showing,
					paymentInstruction: true,
				}

				this.disburseTo = disburseTo
			},
		},
	}
</script>
<style scoped>
	.input-group-text {
		background: #54a7dc;
		border: none;
		color: #fff;
		cursor: pointer;
	}
</style>
