import { render, staticRenderFns } from "./_Syaria.vue?vue&type=template&id=fa86e966&scoped=true&"
import script from "./_Syaria.vue?vue&type=script&lang=js&"
export * from "./_Syaria.vue?vue&type=script&lang=js&"
import style0 from "./_Syaria.vue?vue&type=style&index=0&id=fa86e966&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa86e966",
  null
  
)

export default component.exports