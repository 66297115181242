<template>
	<div>
		<!-- Applicant Profile Section -->
		<vcl-facebook v-show="loading" />
		<div v-show="!loading">
			<b-tab :title="$t('Credit Score', 'Credit Score')" :active="activeTab === 'CreditScore'" @click="onCreditScoreLoad">
				<b-card
					border-variant="primary"
					:header="$t('Application Profile Data', 'Application Profile Data')"
					header-bg-variant="primary"
					header-text-variant="white"
					align="left"
					no-body
					class="mt-3"
				/>
				<b-card>
					<div v-for="(row, index) in listingProfileData" :key="index" class="row">
						<b-col cols="4" class="text-14 font-weight-light text-capitalize pt-2">
							{{ $t(row.text, row.text) }}
						</b-col>
						<b-col cols="2" style="color: green; padding-left: 60px" class="text-14 text-center">
							<b>{{ $t(row.value, row.value) }}</b>
						</b-col>
					</div>
				</b-card>

				<b-card
					border-variant="primary"
					:header="$t('Application Farm Data', 'Application Farm Data')"
					header-bg-variant="primary"
					header-text-variant="white"
					align="left"
					no-body
					class="mt-3"
				/>
				<b-card>
					<div v-for="(row, index) in listingFarmData" :key="index" class="row">
						<b-col cols="4" class="text-14 font-weight-light text-capitalize pt-2">
							{{ $t(row.text, row.text) }}
						</b-col>
						<b-col cols="2" style="color: green; padding-left: 60px" class="text-14 text-center">
							<b>{{ row.value }}</b>
						</b-col>
					</div>
				</b-card>

				<!-- Applicant Profile Section -->
				<b-card border-variant="primary" header-bg-variant="primary" header-text-variant="white" align="left" no-body class="mb-2" />

				<div class="box-finalscore mt-3">
					<b-row align-v="center">
						<b-col cols="3">
							<div style="width: 120px !important; margin-top: -30px" class="text-25">
								<b>{{ $t("Final Score", "Final Score") }}</b>
							</div>
						</b-col>
						<b-col>
							<b-row class="text-center">
								<b-col>
									<b class="text-25">{{ $t(prediction, prediction) }}</b>
									<br />
									{{ $t("Total Score", "Total Score") }}
								</b-col>
								<b-col>
									<div style="width:100px; !important">
										<VueSvgGauge
											:start-angle="-110"
											:end-angle="110"
											:value="Number(score) * 100"
											:separator-step="20"
											:scale-interval="10"
											:inner-radius="80"
										>
											<div class="inner-text">
												<span>{{ parseFloat(score) * 100 }}%</span>
											</div>
										</VueSvgGauge>
									</div>
									<br />
									<div style="width: 100px !important; margin-top: -20px">
										{{ $t("Maximum Points", "Maximum Points") }}
									</div>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</div>
			</b-tab>
		</div>
	</div>
</template>

<script>
	import { findWhere, map } from "underscore"
	import { mapActions, mapState } from "vuex"

	export default {
		props: {
			activeTab: {
				default: null,
				required: true,
				type: String,
			},
		},
		data() {
			return {
				genderOptions: [],
				listingFarmData: [],
				listingProfileData: [],
				loading: false,
				prediction: "",
				score: "0",
			}
		},
		computed: {
			...mapState("OTHERS", ["genderList"]),
		},
		watch: {
			genderList: {
				deep: true,
				handler() {
					this.getGenderOptions()
				},
				immediate: true,
			},
		},
		created() {
			this.getGenderList()
		},
		methods: {
			...mapActions({
				ActionCreditScore: "LOAN/ActionCreditScore",
				getGenderList: "OTHERS/getGenderList",
			}),
			getGenderOptions() {
				this.genderOptions = map(this.genderList, (gender) => {
					return {
						text: gender.value.charAt(0).toUpperCase() + gender.value.slice(1),
						value: parseInt(gender.code, 10),
					}
				})
			},
			toPlainString(num) {
				return ("" + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (a, b, c, d, e) {
					return e < 0 ? b + "0." + Array(1 - e - c.length).join(0) + c + d : b + c + d + Array(e - d.length + 1).join(0)
				})
			},
			onCreditScoreLoad() {
				this.loading = true
				this.ActionCreditScore(this.$route.params.applicationId).then((e) => {
					this.loading = false
					let profileData = []
					let farmData = []
					if (e.dataCreditScore !== null) {
						const sc = e.loanScoring
						this.score = e.loanScoring === null ? "0" : parseFloat(this.toPlainString(sc)).toFixed(4)
						this.prediction = e.prediction
						Object.keys(e.dataCreditScore.profileData).forEach((key) => {
							const k = key.replace(/([a-z])([A-Z])/g, "$1 $2")

							if (key === "isCertification") {
								const getValue = (isCertification) => {
									const matchedIsCertification = findWhere(
										[
											{ text: "No", value: 0 },
											{ text: "Yes", value: 1 },
										],
										{ value: parseInt(isCertification, 10) },
									)
									return matchedIsCertification.text ? matchedIsCertification.text : "-"
								}

								profileData.push({
									text: k.charAt(0).toUpperCase() + k.slice(1).toLowerCase(),
									value: e.dataCreditScore.profileData[key] ? getValue(e.dataCreditScore.profileData[key]) : "-",
								})
							} else if (key === "gender") {
								const getGender = (gender) => {
									const matchedGender = findWhere(this.genderOptions, { value: parseInt(gender, 10) })
									return matchedGender.text ? matchedGender.text : "-"
								}

								profileData.push({
									text: k.charAt(0).toUpperCase() + k.slice(1).toLowerCase(),
									value: e.dataCreditScore.profileData[key] ? getGender(e.dataCreditScore.profileData[key]) : "-",
								})
							} else {
								profileData.push({
									text: k.charAt(0).toUpperCase() + k.slice(1).toLowerCase(),
									value: e.dataCreditScore.profileData[key] ? e.dataCreditScore.profileData[key] : "-",
								})
							}
						})

						Object.keys(e.dataCreditScore.farmData).forEach((key) => {
							const k = key.replace(/([a-z])([A-Z])/g, "$1 $2")
							const obj = {
								text: k.charAt(0).toUpperCase() + k.slice(1).toLowerCase(),
								value: e.dataCreditScore.farmData[key] ? e.dataCreditScore.farmData[key] : "-",
							}
							farmData.push(obj)
						})
					}
					this.listingProfileData = profileData
					this.listingFarmData = farmData
				})
			},
		},
	}
</script>
<style>
	.inner-text {
		max-width: 190px;
		color: red;
		font-size: 40px;
		padding-top: 50px;
		padding-left: 10px;
	}
</style>
