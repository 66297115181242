<template>
	<form-wizard ref="wizard" title subtitle color="#54A7DC" step-size="xs" :start-index="activeIndex" @on-complete="onComplete" @on-change="onChange">
		<tab-content :title="$t('Upload Loan Document Contract', 'Upload Loan Document Contract')" icon="i-Yes" />
		<tab-content :title="$t('Disbursement', 'Disbursement')" icon="i-Yes" />
		<tab-content :title="$t('Repayment', 'Repayment')" icon="i-Yes" />
		<tab-content :title="$t('Done', 'Done')" icon="i-Yes" />
	</form-wizard>
</template>

<script>
	export default {
		name: "LoanContractDetailSteps",
		props: {
			activeIndex: {
				default: 0,
				required: true,
				type: Number,
			},
		},
		emits: ["on-change"],
		watch: {
			activeIndex: {
				handler(newValue, oldValue) {
					if (newValue !== oldValue) {
						this.$refs.wizard.changeTab(oldValue, newValue)
					}
				},
			},
		},
		methods: {
			onChange(_prevIndex, nextIndex) {
				this.$emit("on-change", nextIndex)
			},
			onComplete() {
				this.$swal({
					showConfirmButton: false,
					timer: 1500,
					title: this.$t("Your work has been saved", "Your work has been saved"),
					type: "success",
				})
			},
		},
	}
</script>

<style lang="sass">
	.vue-form-wizard
		.wizard-nav-pills > li > a
			&.disabled
				color: unset

			&:not(.disabled)
				color: #54A7DC

		.wizard-tab-content
			min-height: 0
</style>
